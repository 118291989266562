import { useMutation } from "react-query";
import useNetworkHeaders from "../../../hooks/useNetworkHeaders";
import { postNotifyBulkInvite, PostNotifyBulkInviteResponse } from "../../../queries/notify";

export function useNotifyBulkInviteCreate({
  onSuccess = () => {},
  onError = () => {},
}: { onSuccess?: () => void; onError?: () => void } = {}) {
  const networkHeaders = useNetworkHeaders();

  const [sendBulkInviteMutation, { isLoading: isSendBulkInviteLoading }] = useMutation(
    postNotifyBulkInvite(networkHeaders),
    {
      onSuccess,
      onError,
    },
  );

  const sendBulkInvite = async ({
    recipients,
    subscriptionPreferences,
    options,
  }: {
    recipients: {
      type: "Email" | "Phone";
      identifier: string;
    }[];
    subscriptionPreferences: {
      subscriptionGroupId: number;
      type: "Subscribe" | "Unsubscribe";
    }[];
    options: {
      resendInvitations?: boolean;
      resendOptedOut?: boolean;
      autoEnroll?: {
        value: true;
        justification: string;
      } | null;
    };
  }): Promise<PostNotifyBulkInviteResponse | undefined> => {
    return await sendBulkInviteMutation({
      recipients,
      subscriptionPreferences,
      options,
    });
  };

  return { sendBulkInvite, isSendBulkInviteLoading };
}
