import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import { QueryCache, ReactQueryCacheProvider } from "react-query";
import { createStore, applyMiddleware, compose } from "redux";
import { createBrowserHistory } from "history";
import { ConnectedRouter, routerMiddleware } from "connected-react-router";
import thunkMiddleware from "redux-thunk";
import { apiMiddleware } from "redux-api-middleware";
import rootReducer from "./reducers";
import { checkAuthMiddleware, addMetaMiddleware } from "./middlewares/auth";
import EmployeeWrapper from "./components/EmployeeWrapper";
import * as serviceWorker from "./serviceWorker";
import { NuqsAdapter } from "nuqs/adapters/react";

import "./index.scss";
import "semantic-ui-css/semantic.min.css";
import "./semantic-ui-overrides.scss";
import ErrorBoundary from "./components/ErrorBoundary";
import "react-datepicker/dist/react-datepicker.css";
import AppSettings from "./config";
import mixpanel from "mixpanel-browser";
import { ToastProvider } from "react-toast-notifications";
import Page404 from "./components/global_components/Page404";
import { NotifyApp } from "./features/notifcations/NotifyApp";
import { Network } from "./lib/network";

const App = lazy(() => import("./components/App"));
const Landing = lazy(() => import("./apps/pages/Landing"));
const Login = lazy(() => import("./apps/pages/Login"));
const SSO = lazy(() => import("./apps/pages/SSO"));
const StartSSO = lazy(() => import("./apps/pages/StartSSO"));
const Logout = lazy(() => import("./apps/pages/Logout"));
const Kickout = lazy(() => import("./apps/pages/Kickout"));
const ForgotAccount = lazy(() => import("./apps/pages/ForgotAccount"));
const ResetPassword = lazy(() => import("./apps/pages/ResetPassword"));
const Verify = lazy(() => import("./apps/pages/Verify"));
const SiteInfoApp = lazy(() => import("./components/SiteInfoApp"));
const CatalogApp = lazy(() => import("./components/CatalogApp"));
const PublicApp = lazy(() => import("./apps/PublicApp"));
const CrmApp = lazy(() => import("./apps/CrmApp"));
const CodeApp = lazy(() => import("./apps/CodeApp"));
const CommunicationsApp = lazy(() => import("./features/notifcations/CommunicationsApp"));
const PermitsApp = lazy(() => import("./features/permits/PermitsApp"));
const ContactsApp = lazy(() => import("./features/contacts/ContactsApp"));
const LocationsApp = lazy(() => import("./features/locations/LocationsApp"));
const NewProfileApp = lazy(() => import("./apps/NewProfileApp"));
const AdminApp = lazy(() => import("./apps/AdminApp/AdminApp"));
const ToolsApp = lazy(() => import("./features/tools/ToolsApp"));
const TwitterOAuthCallback = lazy(() => import("./apps/pages/TwitterOAuthCallback"));

const history = createBrowserHistory();
const store = createStore(
  rootReducer(history),
  undefined,
  compose(
    applyMiddleware(
      thunkMiddleware, // dispatch()
      routerMiddleware(history), // For dispatching history actions
      addMetaMiddleware, // Add meta.endpoint to actions
      apiMiddleware, // Execute API calls
      checkAuthMiddleware, // Check for 401s and log out user
    ),
  ),
);
Network.initialize(store, history);
const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      staleTime: Infinity,
    },
  },
});

const loader = <Loader active={true}>Loading...</Loader>;

mixpanel.init(AppSettings.MIXPANEL_TOKEN);

const routing = (
  <ErrorBoundary>
    <ReactQueryCacheProvider queryCache={queryCache}>
      <ToastProvider>
        <Provider store={store as any}>
          <NuqsAdapter>
            <ConnectedRouter history={history}>
              <Suspense fallback={loader}>
                <Switch>
                  <Route path='/' exact component={App} />
                  <Route path='/dashboard' component={App} />
                  <Route path='/:site/tools' component={ToolsApp} />
                  <Route path='/site-info' component={SiteInfoApp} />
                  <Route path='/catalog' component={CatalogApp} />
                  <Route path='/:site/landing' component={Landing} />
                  <Route path='/:site/public' component={PublicApp} />
                  <Route path='/:site/profile-new'>
                    <EmployeeWrapper render={() => <NewProfileApp />} />
                  </Route>
                  <Route path='/:site/permits'>
                    <EmployeeWrapper render={() => <PermitsApp />} />
                  </Route>
                  <Route path='/:site/contacts'>
                    <EmployeeWrapper render={() => <ContactsApp />} />
                  </Route>
                  <Route path='/:site/locations'>
                    <EmployeeWrapper render={() => <LocationsApp />} />
                  </Route>
                  <Route path='/:site/communications'>
                    <EmployeeWrapper render={() => <CommunicationsApp />} />
                  </Route>
                  <Route path='/:site/notify'>
                    <EmployeeWrapper render={() => <NotifyApp />} />
                  </Route>
                  <Route path='/:site/code'>
                    <EmployeeWrapper render={() => <CodeApp />} />
                  </Route>
                  <Route path='/:site/crm'>
                    <EmployeeWrapper render={() => <CrmApp />} />
                  </Route>
                  <Route path='/:site/admin'>
                    <EmployeeWrapper render={() => <AdminApp />} />
                  </Route>
                  <Route path='/:site/forgot' component={ForgotAccount} />
                  <Route path='/:site/reset' component={ResetPassword} />
                  <Route path='/:site/welcome' component={Verify} />
                  <Route path='/:site/logout' component={Logout} />
                  <Route path='/:site/kickout' component={Kickout} />
                  <Route path='/:site/login' component={Login} />
                  <Route path='/oauth/callback' component={SSO} />
                  <Route path='/oauth2/twitter/callback' component={TwitterOAuthCallback} />
                  <Route path='/:site/oauth/start' component={StartSSO} />
                  <Route path='/:site' exact component={Login} />
                  <Route component={Page404} />
                </Switch>
              </Suspense>
            </ConnectedRouter>
          </NuqsAdapter>
        </Provider>
      </ToastProvider>
    </ReactQueryCacheProvider>
  </ErrorBoundary>
);

ReactDOM.render(routing, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
