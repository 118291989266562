import { useMutation, useQueryCache } from "react-query";
import useNetworkHeaders from "../../../hooks/useNetworkHeaders";
import { DANGER_postNotifySubscriberContactMethodsOptIn } from "../../../queries/notify";
import { buildNotifySubscriberContactMethodsQueryKey } from "./useInfiniteNotifySubscriberContactMethods";
import { buildNotifySubscriberContactMethodQueryKey } from "./useNotifySubscriberContactMethod";
import { useToasts } from "react-toast-notifications";

export function useNotifySubscriberContactMethodOptIn() {
  const networkHeaders = useNetworkHeaders();
  const queryCache = useQueryCache();
  const { addToast } = useToasts();

  const [optInSubscriberContactMethodMutation, { isLoading: isOptInSubscriberContactMethodLoading }] = useMutation(
    DANGER_postNotifySubscriberContactMethodsOptIn(networkHeaders),
    {
      onSuccess: async (_, { subscriberContactMethodIds }) => {
        await Promise.all(
          subscriberContactMethodIds.map((subscriberContactMethodId) => {
            return queryCache.invalidateQueries(
              buildNotifySubscriberContactMethodQueryKey({ id: subscriberContactMethodId }),
            );
          }),
        );
        await queryCache.invalidateQueries(buildNotifySubscriberContactMethodsQueryKey());
      },
      onError: () => {
        addToast("Failed to opt-in subscribers.", { appearance: "error", autoDismiss: true });
      },
    },
  );

  /**
   * DANGER: Force opt-in a subscriber contact method and bypass the invite/opt-in process.
   * Justification must be provided for defense against audits.
   */
  const DANGER_optInSubscriberContactMethod = async ({
    subscriberContactMethodIds,
    justification,
  }: {
    subscriberContactMethodIds: number[];
    justification: string;
  }) => {
    if (justification.length < 10) {
      throw new Error("Justification must be at least 10 characters long.");
    }

    return await optInSubscriberContactMethodMutation({
      subscriberContactMethodIds,
      justification,
    });
  };

  return {
    DANGER_optInSubscriberContactMethod,
    isOptInSubscriberContactMethodLoading,
  };
}
