import _ from "lodash";
import React, { ComponentType, PropsWithChildren, useRef, useState } from "react";
import Editor, { theme as EditorTheme } from "@gogovapps/rich-markdown-editor";
import { useDispatch } from "react-redux";
import {
  Label,
  Checkbox,
  Input,
  Select,
  TextArea,
  Form,
  Dropdown,
  Icon,
  Radio,
  CheckboxProps,
  Button,
  DropdownProps,
  // StrictDropdownItemProps,
} from "semantic-ui-react";
import { Control, Controller, DeepMap, FieldError, RegisterOptions } from "react-hook-form";
import DatePicker, { DatePickerProps } from "react-datepicker";
import { SafeMoment } from "./SafeMoment";
import { humanizeString } from "../lib/humanize-string";
import { s3Url } from "../utils/s3_url";
import { upload } from "../actions/upload";
import AppSettings from "../config";
import Tooltip from "./Tooltip";
import { ControlledSelect } from "./ControlledSelect";
import { toDate } from "../lib/date-util";
import {
  DropDownSelector,
  DropDownMultiTypeSelector,
  DropDownRowSelector,
} from "../features/permits/components/DropDownFilter";
import ReactSelect, {
  GroupBase,
  MultiValue,
  components as Components,
  OptionsOrGroups,
  SingleValue,
  createFilter,
  MultiValueGenericProps,
  GroupHeadingProps,
  CSSObjectWithLabel,
  OptionProps,
} from "react-select";
import { WindowedMenuList } from "react-windowed-select";
import Creatable from "react-select/creatable";

import "./ControlledForm.scss";
import classNames from "classnames";
import { useId } from "react-aria";

export { ControlledSelect };

const Readonly = ({ as, value }: any) => {
  const isEmpty = [undefined, null, ""].includes(value);
  return React.createElement(as, !isEmpty ? {} : { style: { color: "rgba(0, 0, 0, 0.6)" } }, [isEmpty ? "N/A" : value]);
};
const isErrorSelector = (errors: any) => (fieldName: string) => !!_.get(errors, `${fieldName}.message`);
const errorMessageSelector = (errors: any) => (fieldName: string) =>
  isErrorSelector(fieldName)
    ? _.get(errors, `${fieldName}.message`).replace(fieldName, humanizeString(fieldName))
    : null;

interface IControlledWysiwyg {
  control: any;
  name: string;
  label?: string;
  placeholder?: string;
  errors?: any;
  site: string;
  folder: string;
  bucket: string;
  height?: string;
  defaultValue?: any;
  required?: boolean;
}

export const ControlledWysiwyg = (props: IControlledWysiwyg) => {
  const dispatch = useDispatch();
  const isError = isErrorSelector(props.errors)(props.name);
  return (
    <Controller
      name={props.name}
      control={props.control}
      defaultValue={props.defaultValue}
      rules={{ required: props.required && "This field is required" }}
      render={({ onChange, name }) => {
        const editorRef: any = useRef();
        return (
          <Form.Field error={isError} required={props.required}>
            {props.label && <label htmlFor={name}>{props.label}</label>}
            <div
              tabIndex={-1}
              role='textbox'
              onClick={(e) => {
                if ((e.target as any).getAttribute("placeholder") === "Search or paste a link…") {
                  return;
                }
                if (!editorRef.current.view.focused) {
                  editorRef.current.focusAtEnd();
                }
              }}
              style={{
                minHeight: props.height,
                background: isError ? "#fff6f6" : "white",
                borderRadius: "4px",
                border: isError ? "1px solid #e0b4b4" : "1px solid rgba(34,36,38,.15)",
                color: isError ? "#9f3a38" : "inherit",
                padding: "6px",
                marginLeft: "24px",
              }}
            >
              <Editor
                ref={editorRef}
                placeholder={props.placeholder}
                defaultValue={props.defaultValue}
                disabledToolbarItems={["mark"]}
                disabledBlockMenuItems={["container_notice", "table", "checkbox_list", "code_block"]}
                theme={
                  {
                    ...EditorTheme,
                    zIndex: "10000",
                  } as any
                }
                uploadImage={async (file: any) => {
                  const uploadOptions = {
                    id: "controlled-wysiwg",
                    site: props.site,
                    file: file,
                    folder: props.folder,
                    bucket: props.bucket,
                  };
                  const uploadResponse = (await dispatch(upload(uploadOptions))) as any;
                  return s3Url(uploadResponse.payload, AppSettings.CONTENT_URL);
                }}
                onChange={(getValue: any) => {
                  onChange(removeExtraCarraigeReturns(getValue()));
                }}
              />
            </div>
            {isError && (
              <Label basic prompt pointing='above' aria-live='polite' id={`${props.name}-error`}>
                {errorMessageSelector(props.errors)(name)}
              </Label>
            )}
          </Form.Field>
        );
      }}
    />
  );
};

// Removes errant '\' characters that show up instead of '\n'
export const removeExtraCarraigeReturns = (originalValue: any): string | null | undefined => {
  if (originalValue === undefined) return null;
  if (originalValue === null) return null;
  let ret = originalValue.replace(/\\n/g, "\n");
  ret = ret.replace(/\n\\/g, "\n");
  ret = ret.replace(/^\\/, "\n");
  return ret;
};

export interface IStrictControlledTextAreaProps {
  control: Control;
  name: string;
  label?: string;
  placeholder?: string;
  readonly?: boolean;
  required?: boolean;
  className?: string;
  errors?: any;
  rows: number;
  style?: any;
  rules?: Exclude<RegisterOptions, "valueAsNumber" | "valueAsDate" | "setValueAs">;
  autoFocus?: boolean;
  defaultValue?: string | any;
  onBlur?: any;
  onKeyPress?: (e: React.KeyboardEvent) => void;
  transform?: { input: (value: any) => string | null | undefined; output: (value: string | null | undefined) => any };
}
interface IControlledTextAreaProps {
  control: any;
  name: string;
  label?: string;
  subLabel?: string;
  placeholder?: string;
  readonly?: boolean;
  required?: boolean;
  className?: string;
  errors?: any;
  rows: number;
  style?: any;
  rules?: any;
  autoFocus?: boolean;
  defaultValue?: string | any;
  onBlur?: any;
  onChange?: any;
  onKeyPress?: (e: React.KeyboardEvent) => void;
  transform?: { input: (value: any) => string | null | undefined; output: (value: string | null | undefined) => any };
}

type IControlledTextArea = <T extends IControlledTextAreaProps = IControlledTextAreaProps>(
  props: T,
) => React.JSX.Element;

export const ControlledTextArea: IControlledTextArea = (props) => {
  const { transform = { input: (value) => value, output: (value) => value } } = props;
  const isError = isErrorSelector(props.errors)(props.name);
  const [isFocused, setIsFocused] = useState(false);
  const id = useId();

  // Looks like ev is an event, not sure of what type though...
  const onBlur = (ev: unknown) => {
    setIsFocused(false);
    if (props.onBlur) props.onBlur(ev);
  };

  return (
    <Controller
      name={props.name}
      control={props.control}
      defaultValue={props.defaultValue}
      rules={Object.assign({}, { required: props.required && "This field is required" }, props.rules)}
      render={({ onChange, value, name }) => {
        return (
          <>
            {props.label && <label htmlFor={id}>{props.label}</label>}
            {props.subLabel && <div className='tw-mb-2 tw-mt-[-4px] tw-text-black-light'>{props.subLabel}</div>}
            {props.readonly && (
              <div className='text-area-read-only'>
                <Readonly as='pre' value={transform.input(value || props.defaultValue)} />
              </div>
            )}
            {!props.readonly && (
              <TextArea
                id={id}
                aria-describedby={`${props.name}-error`}
                aria-invalid={isError}
                style={props.style}
                autoFocus={props.autoFocus}
                placeholder={props.placeholder}
                rows={props.rows}
                name={name}
                value={transform.input(value) ?? ""}
                onChange={(e, { value }) => {
                  onChange(transform.output(value as string));
                  if (props.onChange) props.onChange();
                }}
                onBlur={onBlur}
                onKeyPress={props.onKeyPress}
                required={props.required}
                onFocus={() => setIsFocused(true)}
                className={isFocused ? `tw-ring-2 tw-ring-blue ${props.className}` : `${props.className}`}
              />
            )}
            {isError && (
              <Label basic prompt pointing='above' aria-live='polite' id={`${props.name}-error`}>
                {errorMessageSelector(props.errors)(name)}
              </Label>
            )}
          </>
        );
      }}
    />
  );
};

export interface IStrictControlledInputProps<Name extends string, T> {
  name: Name;
  className?: string;
  control: Control<Record<Name, T>>;
  errors?: DeepMap<Record<Name, T>, FieldError>;
  label?: string;
  subLabel?: string;
  inputLabel?: any;
  inputLabelPosition?: "left" | "right" | "left corner" | "right corner";
  placeholder?: string;
  readonly?: boolean;
  required?: boolean;
  defaultValue?: T;
  type?: string;
  action?: any;
  style?: any;
  disabled?: boolean;
  rules?: Exclude<RegisterOptions, "valueAsNumber" | "valueAsDate" | "setValueAs">;
  icon?: any;
  iconPosition?: any;
  onBlur?: any;
  mask?: Function; // mask to apply to the input value
  fluid?: boolean;
  autoFocus?: boolean;
  tooltip?: JSX.Element;
  unit?: string;
  autoComplete?: AutoFill;
  underText?: string;
  transform?: { input: (value: any) => string | null; output: (value: string | null) => any };
  inputRef?: any;
}

interface IControlledInputProps {
  className?: string;
  control: any;
  name: string;
  label?: string;
  extraLabel?: JSX.Element; // Additional JSX element to be display inline with the label (can be used to add buttons, tooltips, etc.)
  subLabel?: string;
  inputLabel?: any;
  inputLabelPosition?: "left" | "right" | "left corner" | "right corner";
  placeholder?: string;
  errors?: any;
  readonly?: boolean;
  required?: boolean;
  defaultValue?: any;
  type?: string;
  action?: any;
  style?: any;
  disabled?: boolean;
  rules?: Parameters<typeof Controller>[0]["rules"];
  icon?: any;
  iconPosition?: any;
  onBlur?: any;
  onChange?: any;
  mask?: Function; // mask to apply to the input value
  fluid?: boolean;
  autoFocus?: boolean;
  tooltip?: JSX.Element;
  unit?: string;
  autoComplete?: HTMLInputElement["autocomplete"];
  underText?: string;
  transform?: { input: (value: any) => string | null; output: (value: string | null) => any };
  inputRef?: any;
}

type IControlledInput = <T extends IControlledInputProps = IControlledInputProps>(
  props: PropsWithChildren<T>,
) => React.JSX.Element;

export const ControlledInput: IControlledInput = (props) => {
  const id = useId();
  const isError = isErrorSelector(props.errors)(props.name);
  return (
    <Controller
      name={props.name}
      control={props.control}
      defaultValue={props.defaultValue}
      className='controlled-input'
      rules={Object.assign({}, { required: props.required && "This field is required" }, props.rules)}
      render={({ onChange, value, name }) => {
        const defaultValue = props.transform?.input ? props.transform.input(props.defaultValue) : props.defaultValue;
        return (
          <>
            {props.label && (
              <label
                className='tw-inline-block'
                htmlFor={id}
                style={{ marginBottom: !!props.subLabel ? "0" : undefined }}
              >
                {props.label}
                {props.name === "min_days_notice" && (
                  <Tooltip>
                    If set, applicants cannot select dates within the minimum days notice from the date they are
                    applying.
                  </Tooltip>
                )}
                {!!props.tooltip && <Tooltip>{props.tooltip}</Tooltip>}
              </label>
            )}
            {props.extraLabel}
            {props.subLabel && (
              <div style={{ color: "rgba(0, 0, 0, 0.7)", marginTop: "-4px", marginBottom: "4px" }}>
                {props.subLabel}
              </div>
            )}

            {props.readonly && (
              <Readonly
                as='span'
                value={props.transform?.input ? props.transform?.input(value || defaultValue) : value || defaultValue}
              />
            )}
            {!props.readonly && (
              <Input
                className={props.className}
                id={id}
                aria-describedby={`${props.name}-error`}
                aria-invalid={isError}
                type={props.type}
                icon={props.icon}
                disabled={props.disabled}
                iconPosition={props.iconPosition === "left" || (!props.iconPosition && props.icon) ? "left" : undefined}
                placeholder={props.placeholder}
                fluid={props.fluid}
                error={isError}
                autoFocus={props.autoFocus}
                name={name}
                value={
                  props.transform?.input
                    ? props.transform.input(value) ?? defaultValue ?? ""
                    : value ?? defaultValue ?? ""
                }
                onBlur={props.onBlur}
                onChange={(e, { value }) => {
                  // apply mask if one is provided
                  if (props.mask) value = props.mask(value);
                  // apply transformation if provided
                  if (props.transform?.output) (value as string) = props.transform.output(value);
                  onChange(value);
                  if (props.onChange) props.onChange();
                }}
                style={props.style}
                action={props.action}
                label={props.inputLabel}
                labelPosition={props.inputLabelPosition}
                autoComplete={props.autoComplete}
                required={props.required}
                ref={props.inputRef}
              >
                {props.children}
              </Input>
            )}
            {props.underText && <div className='input-under-text'>{props.underText}</div>}
            {isError && (
              <Label basic prompt pointing='above' aria-live='polite' id={`${props.name}-error`}>
                {errorMessageSelector(props.errors)(name)}
              </Label>
            )}
          </>
        );
      }}
    />
  );
};

interface IControlledSelect {
  control: any;
  name: string;
  label?: string;
  placeholder?: string;
  errors?: any;
  readonly?: boolean;
  search?: boolean;
  selectProps?: any;
  defaultValue?: any;
  disabled?: boolean;
  required?: boolean;
  inline?: boolean;
}

export const ControlledLabelSelect = (props: IControlledSelect) => {
  const step_types: any[] = ["review", "payment", "document", "inspection"];

  return (
    <Controller
      name={props.name}
      control={props.control}
      defaultValue={props.defaultValue}
      rules={{ required: props.required && "This field is required" }}
      render={({ onChange, value, name }) => {
        return (
          <div className='ControlledLabelSelect'>
            {props.label && (
              <label className='step_type_label' htmlFor={name}>
                {props.label}
              </label>
            )}
            <div className='selector'>
              {!props.readonly &&
                step_types.map((type: string) => (
                  <Label
                    circular
                    key={type}
                    className={`step_type_selector ${value === type ? "active" : ""}`}
                    onClick={() => onChange(type)}
                    tabIndex={0}
                    onKeyPress={(e: React.KeyboardEvent) => {
                      if (e.key === "Enter") {
                        onChange(type);
                      }
                    }}
                  >
                    {_.capitalize(type)}
                  </Label>
                ))}
            </div>
            {!value && (
              <Label basic prompt pointing='above'>
                This field is required
              </Label>
            )}
          </div>
        );
      }}
    />
  );
};

interface IControlledTagInput {
  control: any;
  name: string;
  label?: string;
  placeholder?: string;
  noResultsMessage?: string;
  errors?: any;
  rules?: any;
  selectProps?: any;
  required?: boolean;
  defaultValue?: any;
  singleSelect?: boolean;
  type?: string;
  title?: any;
}

export const ControlledTagInput = (props: IControlledTagInput) => {
  const isError = isErrorSelector(props.errors)(props.name);
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue}
      render={({ onChange, value, name }) => {
        const options = value?.map((item: any) => ({ key: item, text: item, value: item }));
        return (
          <>
            {props.label && <label htmlFor={name}>{props.label}</label>}
            <Select
              search
              selection
              multiple
              allowAdditions
              options={options}
              name={name}
              error={isError}
              value={value || []}
              placeholder={props.placeholder ?? ""}
              required={props.required}
              noResultsMessage={props.noResultsMessage}
              onChange={(e: any, data: any) => onChange(data.value)}
              {...props.selectProps}
            />
          </>
        );
      }}
    ></Controller>
  );
};

interface IControlledCheckbox {
  name: string;
  className?: string;
  control?: any;
  label?: any;
  disabled?: boolean;
  required?: boolean;
  readonly?: boolean;
  radio?: boolean;
  toggle?: boolean;
  defaultValue?: any;
  onBlur?: any;
  type?: any;
  style?: any;
  customAriaLabel?: string;
  onClick?: (event: React.MouseEvent<HTMLInputElement, MouseEvent>, data: CheckboxProps) => void;
  transform?: {
    input: (value: any) => any;
    output: (value: any) => any;
  };
  labelOptions?: [string, string];
}

export const ControlledCheckbox = ({
  name,
  control,
  label,
  disabled,
  required,
  readonly,
  radio,
  toggle,
  defaultValue,
  onBlur,
  type,
  style,
  className,
  onClick,
  customAriaLabel,
  transform = {
    input: (value) => value,
    output: (value) => value,
  },
  labelOptions,
}: IControlledCheckbox) => {
  const generateLabel = (value: any, name: string) => {
    if (labelOptions) {
      return (
        <label htmlFor={name}>
          {labelOptions[transform.input(value) == "true" || transform.input(value) === true ? 1 : 0]}
        </label>
      );
    }
    if (label) {
      return <label htmlFor={name}>{label}</label>;
    }
  };
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: required && "This field is required" }}
      defaultValue={defaultValue}
      render={({ onChange, value, name }) => {
        return readonly ? (
          <div style={{ display: "flex" }}>
            <Icon
              size='large'
              name={
                transform.input(value) == "true" || transform.input(value) === true
                  ? "check square outline"
                  : "square outline"
              }
            />
            {generateLabel(value, name)}
          </div>
        ) : (
          <Checkbox
            name={name}
            type={type}
            aria-label={customAriaLabel || label}
            label={generateLabel(value, name)}
            checked={transform.input(value) == "true" || transform.input(value) === true}
            radio={radio}
            onClick={onClick}
            toggle={toggle}
            disabled={disabled || readonly}
            onChange={(e, { checked }) => {
              onChange(transform.output(checked));
              if (onBlur) {
                onBlur();
              }
            }}
            style={style}
            className={className}
            required={required}
          />
        );
      }}
    />
  );
};

type ControlledDatePickerProps = {
  control: any;
  name: string;
  label?: string;
  placeholder?: string;
  readonly?: boolean;
  required?: boolean;
  errors?: any;
  defaultValue?: any;
  min?: any;
  citizen?: boolean;
  onBlur?: () => void;
  transform?: {
    input: (value: any) => any;
    output: (value: any) => any;
  };
} & Partial<DatePickerProps>;

/**
 * @deprecated Use ControlledDateTime instead
 * @param props
 * @returns
 */
export const ControlledDatePicker = (props: ControlledDatePickerProps) => {
  const { transform = { input: (value) => value, output: (value) => value } } = props;
  const isError = isErrorSelector(props.errors)(props.name);
  let minDate: any = new Date();
  minDate.setDate(minDate.getDate() + parseInt(props.min));
  minDate = props.min && props.citizen ? minDate : null;
  if (parseInt(props.min) === 0) {
    minDate = undefined;
  }

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const handleFocus = () => {
    setIsCalendarOpen(false);
  };

  const CustomContainer = ({ children }: any) => (
    <div role='dialog' aria-modal='true' className='custom-datepicker-container'>
      {children}
    </div>
  );

  // Common DatePicker properties
  const commonDatePickerProps = {
    "minDate": minDate,
    "aria-describedby": `${props.name}-error`,
    "aria-invalid": isError,
    "placeholderText": props.placeholder,
    "required": false,
    "id": props.name,
    ...props,
  };

  if (props.citizen) {
    commonDatePickerProps.open = isCalendarOpen;
    commonDatePickerProps.onFocus = handleFocus;
    commonDatePickerProps.onCalendarClose = () => setIsCalendarOpen(false);
    commonDatePickerProps.onClickOutside = () => setIsCalendarOpen(false);
    commonDatePickerProps.calendarContainer = CustomContainer;
  }

  return (
    <Controller
      name={props.name}
      control={props.control}
      defaultValue={props.defaultValue}
      rules={{ required: props.required && "This field is required" }}
      render={({ onChange, value, name }) => {
        const readonlyValue = transform.input(value || props.defaultValue) ? (
          <SafeMoment format={"MMM D, YYYY"}>{transform.input(value) || props.defaultValue}</SafeMoment>
        ) : (
          <span style={{ color: "gray" }}>N/A</span>
        );
        return (
          <>
            {props.label && <label htmlFor={name}>{props.label}</label>}
            {props.readonly ? (
              readonlyValue
            ) : (
              <div className='controlled-form-date-picker'>
                <DatePicker
                  onChange={(date: Date | null) => {
                    onChange(transform.output(date));
                    if (props.onBlur) {
                      props.onBlur();
                    }
                  }}
                  selected={toDate(transform.input(value))}
                  {...(commonDatePickerProps as any)}
                />
                {props.citizen && (
                  <>
                    <Button
                      aria-label={
                        isCalendarOpen ? `Close calendar for ${props.label}` : `Open calendar for ${props.label}`
                      }
                      secondary
                      size='huge'
                      type='button'
                      className='icon-only-button tw-ml-2'
                      icon='calendar alternate outline'
                      aria-haspopup='dialog'
                      onClick={() => {
                        setIsCalendarOpen(!isCalendarOpen);
                        setTimeout(() => {
                          const theCalendarButton = document.querySelector(".react-datepicker__navigation--next");
                          const selectedDay = document.querySelector(".react-datepicker__day--selected");
                          if (selectedDay instanceof HTMLElement) {
                            selectedDay.focus();
                          } else if (theCalendarButton instanceof HTMLElement) {
                            theCalendarButton.focus();
                          }
                        }, 0);
                      }}
                    />
                  </>
                )}
              </div>
            )}
            {isError && (
              <Label basic prompt pointing='above' aria-live='polite' id={`${props.name}-error`}>
                {errorMessageSelector(props.errors)(name)}
              </Label>
            )}
          </>
        );
      }}
    />
  );
};

/**
 * @deprecated
 */
export const ControlledDropdown = (props: IControlledTagInput) => {
  const { name, control, rules, defaultValue, label, singleSelect, placeholder, selectProps, type = "single" } = props;
  const isError = isErrorSelector(props.errors)(props.name);
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ onChange, value, name }) => {
        return (
          <>
            {label && (
              <label htmlFor={name} style={{ fontWeight: "bold" }}>
                {label}
              </label>
            )}
            {type === "single" && (
              <DropDownSelector
                fluid
                multiple
                search
                selection
                singleSelect={singleSelect}
                name={name}
                error={isError}
                closeOnChange={true}
                value={value}
                title='search'
                defaultTitle={placeholder}
                onChange={(data: any) => {
                  onChange(data);
                }}
                {...selectProps}
              />
            )}
            {type === "multi" && (
              <DropDownMultiTypeSelector
                fluid
                multiple
                search
                selection
                singleSelect={singleSelect}
                name={name}
                error={isError}
                closeOnChange={true}
                value={value}
                title='search'
                defaultTitle={placeholder}
                onChange={(data: any) => {
                  onChange(data);
                }}
                {...selectProps}
              />
            )}
          </>
        );
      }}
    ></Controller>
  );
};

interface IControlledRowDropdown {
  control: any;
  name: string;
  label?: string;
  placeholder: string;
  noResultsMessage?: string;
  errors?: any;
  rules?: any;
  selectProps?: any;
  required?: boolean;
  defaultValue?: any;
  singleSelect?: boolean;
  onChange?: (value: any) => void;
}

export const ControlledRowDropdown = (props: IControlledRowDropdown) => {
  const isError = isErrorSelector(props.errors)(props.name);
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue}
      render={({ onChange, value, name }) => {
        return (
          <>
            {props.label && <label htmlFor={name}>{props.label}</label>}
            <DropDownRowSelector
              fluid
              multiple
              search
              selection
              singleSelect={props.singleSelect}
              name={name}
              error={isError}
              closeOnChange={true}
              value={value}
              title='citizens'
              defaultTitle={props.placeholder}
              onChange={(data: any) => {
                onChange(data);
              }}
              onChangeFilter={(filter: any) => {
                if (props.onChange) {
                  props.onChange(filter);
                }
              }}
              {...props.selectProps}
            />
          </>
        );
      }}
    ></Controller>
  );
};

interface IControlledCustomDropdown {
  control?: any;
  name: string;
  label?: string;
  placeholder?: string;
  errors?: any;
  rules?: any;
  clearable?: any;
  allowAdditions?: any;
  options: {
    key?: string;
    value?: any;
    text?: string;
  }[];
  required?: boolean;
  renderLabel?: any;
  style?: any;
  icon?: string;
  multiple?: any;
  defaultValue?: any;
  readonly?: boolean;
  autoFocus?: boolean;
  loading?: boolean;
  onChange?: (value: any) => void;
  onSearchChange?: (value: any) => void;
  onAddItem?: (event: any, data: any) => void;
  customDropdownItems: React.ReactNode[];
}

export const ControlledCustomDropdown = (props: IControlledCustomDropdown) => {
  const isError = isErrorSelector(props.errors)(props.name);
  return (
    <Controller
      {...props}
      name={props.name}
      control={props.control}
      defaultValue={props.defaultValue}
      render={({ onChange, value, name }) => {
        if (props.readonly)
          return (
            <>
              {props.label && <label htmlFor={name}>{props.label}</label>}
              <Readonly as='span' value={value || props.defaultValue} />
            </>
          );
        return (
          <>
            {props.label && <label htmlFor={name}>{props.label}</label>}
            <Dropdown
              placeholder={props.placeholder}
              search
              fluid
              selection
              loading={props.loading}
              searchInput={{ autoFocus: props.autoFocus }}
              renderLabel={props.renderLabel}
              multiple={props.multiple}
              clearable={props.clearable}
              allowAdditions={props.allowAdditions}
              icon={props.icon}
              name={name}
              error={isError}
              value={value}
              options={props.options}
              onChange={(e: any, data: any) => onChange(data)}
              onSearchChange={(e: any, data: any) => {
                if (props.onSearchChange) props.onSearchChange(data.searchQuery);
              }}
              onAddItem={(e, data) => {
                !!props.onAddItem && props.onAddItem(e, data);
              }}
              style={props.style}
            >
              {<Dropdown.Menu>{props.customDropdownItems}</Dropdown.Menu>}
            </Dropdown>
            {isError && (
              <Label basic prompt pointing='above'>
                {errorMessageSelector(props.errors)(name)}
              </Label>
            )}
          </>
        );
      }}
    ></Controller>
  );
};

export interface IStrictControlledDropdownV2Props {
  control?: Control;
  name: string;
  label?: string;
  placeholder?: string;
  errors?: any;
  rules?: Exclude<RegisterOptions, "valueAsNumber" | "valueAsDate" | "setValueAs">;
  clearable?: any;
  allowAdditions?: any;
  // options: StrictDropdownItemProps[]; // TODO: Breaks stuff, needs to be revisited
  options: any[];
  required?: boolean;
  renderLabel?: any;
  style?: any;
  icon?: string;
  multiple?: boolean;
  defaultValue?: any;
  readonly?: boolean;
  loading?: boolean;
  tooltip?: any;
  autoFocus?: boolean;
  additionLabel?: string;
  disabled?: boolean;
  onChange?: (value: any) => void;
  onAddItem?: (event: any, data: any) => void;
  onBlur?: (event: React.FocusEvent<HTMLElement>, data: DropdownProps) => void;
  underText?: string;
}

interface IControlledDropdownV2Props {
  className?: string;
  control?: any;
  name: string;
  label?: string;
  placeholder?: string;
  errors?: any;
  rules?: any;
  clearable?: any;
  allowAdditions?: any;
  // options: StrictDropdownItemProps[]; // TODO: Breaks stuff, needs to be revisited
  options: any[];
  required?: boolean;
  renderLabel?: any;
  style?: any;
  icon?: string;
  multiple?: boolean;
  defaultValue?: any;
  readonly?: boolean;
  tooltip?: any;
  loading?: boolean;
  autoFocus?: boolean;
  additionLabel?: string;
  disabled?: boolean;
  onChange?: (value: any) => void;
  onAddItem?: (event: any, data: any) => void;
  onBlur?: (event: React.FocusEvent<HTMLElement>, data: DropdownProps) => void;
  underText?: string;
  selectOnBlur?: boolean;
  onSearchChange?: (value: any) => void;
}

type IControlledDropdownV2 = <T extends IControlledDropdownV2Props = IControlledDropdownV2Props>(
  props: T,
) => React.JSX.Element;

export const ControlledDropdownV2: IControlledDropdownV2 = (props) => {
  const isError = isErrorSelector(props.errors)(props.name);

  return (
    <Controller
      {...props}
      name={props.name}
      control={props.control}
      rules={Object.assign({}, { required: props.required && "This field is required" }, props.rules)}
      defaultValue={props.defaultValue}
      render={({ onChange, value, name }) => {
        const shouldDefaultToArray = value === undefined && props?.multiple;
        value = shouldDefaultToArray ? [] : value;
        const readonlyValue = props.options?.find((option: any) => option.value === (value || props.defaultValue))
          ?.text;
        if (props.readonly)
          return (
            <>
              {props.label && <label htmlFor={name}>{props.label}</label>}
              {!props.multiple && <Readonly as='span' value={readonlyValue ?? value} />}
              {props.multiple && <Readonly as='span' value={readonlyValue ?? value.join(" • ")} />}
            </>
          );
        return (
          <>
            {props.label && (
              <label htmlFor={name}>
                {props.label}
                {props.tooltip && <Tooltip>{props.tooltip}</Tooltip>}
              </label>
            )}
            <Dropdown
              selectOnBlur={props.selectOnBlur}
              className={props.className}
              placeholder={props.placeholder}
              search
              fluid
              selection
              loading={props.loading}
              searchInput={{ autoFocus: props.autoFocus }}
              renderLabel={props.renderLabel}
              multiple={props.multiple}
              clearable={props.clearable}
              allowAdditions={props.allowAdditions}
              additionLabel={props.additionLabel}
              icon={props.icon}
              name={name}
              error={isError}
              value={value}
              options={props.options}
              disabled={props.disabled}
              onChange={(e: any, data: any) => {
                if (props.onChange) {
                  onChange(data.value);
                  props.onChange(data.value);
                } else {
                  onChange(data.value);
                }
              }}
              onAddItem={(e, data) => {
                !!props.onAddItem && props.onAddItem(e, data);
              }}
              onBlur={(e, data) => {
                if (props.onBlur) {
                  props.onBlur(e, data);
                }
              }}
              onSearchChange={(e, data) => {
                if (props.onSearchChange) {
                  props.onSearchChange(data.searchQuery);
                }
              }}
              style={props.style}
            />
            {props.underText && <div className='input-under-text'>{props.underText}</div>}
            {isError && (
              <Label basic prompt pointing='above'>
                {errorMessageSelector(props.errors)(name)}
              </Label>
            )}
          </>
        );
      }}
    ></Controller>
  );
};

interface IControlledAccessibleDropdown {
  control?: any;
  name: string;
  label?: string;
  errors?: any;
  rules?: any;
  options: {
    key?: string;
    value?: string;
    text?: string;
  }[];
  required?: boolean;
  defaultValue?: string;
  readonly?: boolean;
  tooltip?: string;
  disabled?: boolean;
  onChange?: (value: any) => void;
}

export const ControlledAccessibleDropdown = (props: IControlledAccessibleDropdown) => {
  const isError = isErrorSelector(props.errors)(props.name);

  return (
    <Controller
      {...props}
      name={props.name}
      control={props.control}
      rules={Object.assign({}, { required: props.required && "This field is required" }, props.rules)}
      defaultValue={props.defaultValue}
      render={({ value, name, onChange, onBlur, ref }) => {
        if (props.readonly)
          return (
            <>
              {props.label && <label htmlFor={name}>{props.label}</label>}
              <Readonly as='span' value={value || props.defaultValue} />
            </>
          );
        return (
          <>
            {props.label && (
              <label htmlFor={name}>
                {props.label}
                {props.tooltip && <Tooltip>{props.tooltip}</Tooltip>}
              </label>
            )}
            <div className='custom-select-wrapper'>
              <select
                id={props.name}
                aria-describedby={`${props.name}-error`}
                aria-invalid={isError}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                ref={ref}
                disabled={props.disabled}
                required={props.required}
              >
                <option value=''>{"Select..."}</option>
                {props.options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </select>
              <Icon name='caret down' />
            </div>
            {isError && (
              <Label basic prompt pointing='above' aria-live='polite' id={`${props.name}-error`}>
                {errorMessageSelector(props.errors)(name)}
              </Label>
            )}
          </>
        );
      }}
    ></Controller>
  );
};

export type VirtualDropdownOption<T = any> = {
  key?: string | number;
  value: T;
  label: string;
  color?: string;
  isDisabled?: boolean;
};

interface IControlledVirtualDropdown {
  control?: any;
  name: string;
  label?: string;
  placeholder?: string;
  errors?: any;
  clearable?: any;
  options: OptionsOrGroups<VirtualDropdownOption, GroupBase<VirtualDropdownOption>>;
  multiple?: boolean;
  defaultValue?: VirtualDropdownOption | null | any;
  searchByGroup?: boolean;
  useVirtualList?: boolean;
  rules?: any;
  readonly?: boolean;
  transform?: {
    input: (value: any) => VirtualDropdownOption | VirtualDropdownOption[] | null;
    output: (value: VirtualDropdownOption | readonly VirtualDropdownOption[] | null) => any;
  };
  onChange?: (newValue: MultiValue<VirtualDropdownOption> | SingleValue<VirtualDropdownOption>) => void;
  components?: IControlledVirtualDropdownComponents;
  styles?: IControlledVirtualDropdownStyles;
  maxMenuHeight?: number;
  hideBorder?: boolean;
  allowAdditions?: boolean;
  onCreateOption?: (inputValue: string) => void;
  disabled?: boolean;
  className?: string;
  overWriteOnChangeEvent?: boolean;
}

interface IControlledVirtualDropdownStyles {
  control: CSSObjectWithLabel;
}

/**
 * The components of the dropdown can be swapped out for custom components. For a full list, see the link
 * @see https://react-select.com/components#replaceable-components
 *
 * For the default values of the components, see the MultiValueLabel example. The defaults for the other
 * components should be in the same place.
 */
interface IControlledVirtualDropdownComponents {
  multiValueLabel?: ComponentType<
    MultiValueGenericProps<VirtualDropdownOption, boolean, GroupBase<VirtualDropdownOption>>
  >;
  groupHeading?: ComponentType<GroupHeadingProps<VirtualDropdownOption, boolean, GroupBase<VirtualDropdownOption>>>;
  option?: ComponentType<OptionProps<VirtualDropdownOption, boolean, GroupBase<VirtualDropdownOption>>>;
}

export function ControlledVirtualDropdown({
  name,
  control,
  options,
  label,
  placeholder,
  errors,
  clearable = false,
  multiple = false,
  defaultValue,
  searchByGroup = false,
  useVirtualList = true,
  rules,
  readonly = false,
  transform = { input: (value) => value, output: (value) => value },
  onChange,
  components,
  styles,
  maxMenuHeight,
  hideBorder = false,
  allowAdditions = false,
  onCreateOption,
  disabled = false,
  className = "",
  overWriteOnChangeEvent = false,
}: IControlledVirtualDropdown) {
  const isError = isErrorSelector(errors)(name);

  const Component = allowAdditions ? Creatable : ReactSelect;

  const filterByGroupsAndOptions = ({ label, value }: { label: string; value: string }, query: string) => {
    const labelLowerCase = label.toLowerCase();
    const valueLowerCase = value.toLowerCase();
    const queryLowerCase = query.toLowerCase();

    if (labelLowerCase.includes(queryLowerCase) || valueLowerCase.includes(queryLowerCase)) return true;

    const matchingOptionsAndGroups = options.filter(
      (optionOrGroup) =>
        (optionOrGroup as GroupBase<VirtualDropdownOption>).label?.toLowerCase().includes(queryLowerCase),
    );

    if (!!matchingOptionsAndGroups.length) {
      for (const optionOrGroup of matchingOptionsAndGroups) {
        const group = optionOrGroup as GroupBase<VirtualDropdownOption>;
        const isGroup = !!group.options;
        if (isGroup) {
          const option = group.options.find(
            (option) => option.value.toLowerCase() === valueLowerCase || option.label.toLowerCase() === labelLowerCase,
          );
          if (!!option) return true;
        }
      }
    }
    return false;
  };

  const customOnChange = (newValue: MultiValue<VirtualDropdownOption> | SingleValue<VirtualDropdownOption>) => {
    onChange && onChange(newValue);
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ onChange, value, name }) => {
        return (
          <>
            {!!label && <label htmlFor={name}>{label}</label>}
            {readonly && !multiple && <Readonly as='span' value={transform.input(value ?? defaultValue)} />}
            {readonly && multiple && (
              <Readonly
                className={className}
                as='span'
                value={(transform.input(value ?? defaultValue) as VirtualDropdownOption[] | null)
                  ?.map((option) => {
                    return option.label;
                  })
                  .join(" • ")}
              />
            )}
            {!readonly && (
              <Component
                className={className}
                createOptionPosition='first'
                formatCreateLabel={(inputValue: any) => `Add "${inputValue}"`}
                isDisabled={disabled}
                onCreateOption={(inputValue) => {
                  onCreateOption && onCreateOption(inputValue);
                }}
                maxMenuHeight={maxMenuHeight}
                unstyled
                components={{
                  MultiValueLabel:
                    components?.multiValueLabel !== undefined ? components.multiValueLabel : Components.MultiValueLabel,
                  DropdownIndicator: () => <i style={{ width: "6px", height: "18px" }} className='dropdown icon'></i>,
                  ...(useVirtualList ? { MenuList: WindowedMenuList } : {}),
                  GroupHeading:
                    components?.groupHeading !== undefined ? components.groupHeading : Components.GroupHeading,
                  Option: components?.option !== undefined ? components.option : Components.Option,
                }}
                classNames={{
                  multiValue: () => "ui label",
                }}
                styles={{
                  control: (base, props) => ({
                    ...base,
                    "display": "flex",
                    "padding": "0 12px 0 16px",
                    "paddingLeft": props.isMulti && props.hasValue ? "4px" : "16px",
                    "color": "rgba(0, 0, 0, 0.87)",
                    "border": hideBorder ? 0 : "1px solid rgba(34, 36, 38, 0.15)",
                    "backgroundColor": isError ? "#FFF6F6" : undefined,
                    "borderRadius": props.menuIsOpen ? "0.28571429rem 0.28571429rem 0 0" : "0.28571429rem",
                    "borderColor": isError ? "#E0B4B4" : props.menuIsOpen ? "#96C8DA" : undefined,
                    "boxShadow": props.menuIsOpen ? "0px 2px 3px 0px rgba(34, 36, 38, 0.15)" : undefined,
                    "borderBottomColor": props.menuIsOpen ? "rgba(0, 0, 0, 0)" : undefined,
                    "transition": "none",
                    ":hover": {
                      cursor: "text",
                    },
                    ...(styles && styles?.control),
                  }),
                  valueContainer: (base) => ({
                    ...base,
                    color: "rgba(0, 1, 0, 0.87)",
                    overflow: "auto",
                  }),
                  indicatorsContainer: (base) => ({
                    ...base,
                    "gap": "2px",
                    "color": "rgba(0, 1, 0, 0.87)",
                    ":hover": {
                      cursor: "pointer",
                    },
                  }),
                  menu: (base) => ({
                    ...base,
                    backgroundColor: "white",
                    borderLeft: hideBorder ? 0 : "1px solid rgba(34, 36, 38, 0.15)",
                    borderRight: hideBorder ? 0 : "1px solid rgba(34, 36, 38, 0.15)",
                    borderBottom: hideBorder ? 0 : "1px solid rgba(34, 36, 38, 0.15)",
                    borderColor: "#96C8DA",
                    borderRadius: "0 0 0.28571429rem 0.28571429rem",
                    boxShadow: "0px 2px 3px 0px rgba(34, 36, 38, 0.15)",
                    marginTop: "-1px",
                    overflow: "auto",
                    zIndex: 3,
                  }),
                  option: (base, props) => {
                    return {
                      ...base,
                      "padding": "8px 16px",
                      "transition": "0.3s",
                      "backgroundColor": (props.data as VirtualDropdownOption)?.color
                        ? (props.data as VirtualDropdownOption)?.color
                        : props.isSelected
                        ? "#dcdfec"
                        : props.isFocused
                        ? "rgba(0, 0, 0, 0.03)"
                        : undefined,
                      "borderTop": "1px solid #FAFAFA",
                      "color": props.isDisabled ? "rgba(0, 0, 0, 0.4)" : undefined,
                      ":hover": {
                        cursor: "pointer",
                        backgroundColor: !(props.data as VirtualDropdownOption)?.color ? "#dcdfec" : undefined,
                        filter: (props.data as VirtualDropdownOption)?.color ? "brightness(80%)" : undefined,
                      },
                    };
                  },
                  groupHeading: (base) => ({
                    ...base,
                    textTransform: "uppercase",
                    color: "rgba(0, 0, 0, 0.85)",
                    fontSize: "0.78571429em",
                    fontWeight: "bold",
                    padding: "16px 0px 4px 16px",
                  }),
                  multiValue: (base) => ({
                    ...base,
                    display: "flex !important",
                    alignItems: "center",
                    cursor: "default",
                  }),
                  multiValueRemove: (base) => ({
                    ...base,
                    cursor: "pointer",
                  }),
                  placeholder: (base) => ({
                    ...base,
                    color: "rgba(191, 191, 191, 0.87)",
                  }),
                  noOptionsMessage: (base) => ({
                    ...base,
                    padding: "8px 16px",
                    borderTop: "1px solid #FAFAFA",
                  }),
                }}
                isClearable={clearable}
                isSearchable
                value={transform.input(value)}
                onChange={(value) => {
                  if (!overWriteOnChangeEvent) onChange(transform.output(value));
                  customOnChange(value);
                }}
                name={name}
                options={options}
                isMulti={multiple}
                filterOption={
                  !!searchByGroup
                    ? filterByGroupsAndOptions
                    : createFilter({ ignoreAccents: false, stringify: (option) => option.label })
                }
                defaultValue={transform.input(defaultValue)}
                placeholder={placeholder}
              />
            )}
            {isError && (
              <Label basic prompt pointing='above'>
                {errorMessageSelector(errors)(name)}
              </Label>
            )}
          </>
        );
      }}
    />
  );
}

interface IControlledRadioGroup {
  control: any;
  name: string;
  options: {
    key: string;
    value?: string | number;
    text?: string;
    onClick?: () => void;
    ariaLabel?: string;
  }[];
  defaultValue?: string | number;
  direction?: "horizontal" | "vertical";
  readonly?: boolean;
}

export const ControlledRadioGroup = (props: IControlledRadioGroup) => {
  const { direction = "vertical" } = props;

  return (
    <Controller
      name={props.name}
      control={props.control}
      defaultValue={props.defaultValue}
      render={({ onChange, value, name }) => (
        <>
          {props.readonly && <Readonly as='span' value={value} />}
          {!props.readonly && (
            <Form
              as={"div"}
              className={classNames("tw-flex", {
                "tw-flex-col tw-gap-1": direction === "vertical",
                "tw-flex-row tw-gap-6": direction === "horizontal",
              })}
            >
              {props.options.map((option) => (
                <Form.Field key={option.key} className='tw-mb-0'>
                  <Radio
                    key={option.key}
                    label={option.text}
                    name={name}
                    value={option.value}
                    checked={value === option.value}
                    onClick={() => option.onClick && option.onClick()}
                    onChange={(e, { value }) => onChange(value)}
                    aria-label={option.ariaLabel}
                  />
                </Form.Field>
              ))}
            </Form>
          )}
        </>
      )}
    />
  );
};

interface IControlledInlineRadioGroup {
  control: any;
  name: string;
  options: {
    key: string;
    value?: string | number;
    text?: string;
    disabled?: boolean;
  }[];
  defaultValue?: string | number;
  label?: string;
  isReadOnly?: boolean;
}

export const ControlledInlineRadioGroup = (props: IControlledInlineRadioGroup) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      defaultValue={props.defaultValue}
      render={({ onChange, value, name }) => (
        <Form.Group inline>
          {props.label && <label>{props.label}</label>}
          {props.options.map((option) => (
            <Form.Field key={option.key}>
              <Radio
                key={option.key}
                label={option.text}
                name={name}
                value={option.value}
                checked={value === option.value}
                onChange={(e, { value }) => onChange(value)}
                disabled={option.disabled || props.isReadOnly}
              />
            </Form.Field>
          ))}
        </Form.Group>
      )}
    />
  );
};
