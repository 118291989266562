import React, { useEffect, useMemo } from "react";
import { faEnvelope, faMobile, faPhone, faPhoneVolume } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { formatDistanceToNow } from "date-fns";
import { useQueryState } from "nuqs";
import { Column as ReactTableColumn, useColumnOrder, useSortBy, useTable } from "react-table";
import { AutoSizer, Column, InfiniteLoader, Table } from "react-virtualized";
import { Button, Checkbox, Icon, Loader, Popup, Segment } from "semantic-ui-react";
import { ParsedPhoneNumber } from "../../../../../components/global_components/form_components/ControlledPhoneNumber";
import { ZonedDateTime } from "../../../../../components/global_components/ZonedDateTime";
import { pluralize } from "../../../../../utils/pluralize";
import ContactConnectedProfilePopup from "../../../../contacts/components/ContactConnectedProfilePopup";
import { ContactAvatar } from "../../../../permits/components/ContactAvatar";
import { useBulkSelect } from "../../../hooks/useBulkSelect";
import { useInfiniteNotifySubscriberContactMethods } from "../../../hooks/useInfiniteNotifySubscriberContactMethods";
import { nuqsSortParser } from "../../../util/nuqsSortParser";

import OverflowTextTooltip from "../../../../../components/global_components/OverflowTextTooltip";
import "./NotifySubscribersInfiniteTable.scss";
import { AddSubscribersToSubscriptionGroupsPopup } from "./popups/AddSubscribersToSubscriptionGroupsPopup";
import { RemoveSubscribersFromSubscriptionGroupsPopup } from "./popups/RemoveSubscribersFromSubscriptionGroupsPopup";
import { OptOutSubscribersPopup } from "./popups/OptOutSubscribersPopup";
import { GetNotifySubscriberContactMethodsResponse } from "../../../../../queries/notify";
import { useNotifyBulkInviteCreate } from "../../../hooks/useNotifyBulkInviteCreate";
import { useToasts } from "react-toast-notifications";
import { OptInSubscribersPopup } from "./popups/OptInSubscribersPopup";
import { useFlyout } from "../../../../../components/global_components/Flyout";
import { SubscriberDetailFlyout } from "./flyouts/SubscriberDetailFlyout";
import { InviteSubscribersFlyout } from "./flyouts/InviteSubscribersFlyout";

export type NotifySubscribersInfiniteTableType = "Phone" | "Email" | "App";
export type NotifySubscribersInfiniteTableView = "Enrolled" | "Invited" | "OptedOut";

export function NotifySubscribersInfiniteTable({
  filters,
}: {
  filters: {
    status: NotifySubscribersInfiniteTableView;
    query: string;
    subscriptionGroups: number[];
    types: NotifySubscribersInfiniteTableType[];
  };
}) {
  const { addToast } = useToasts();
  const [sortBy, setSortBy] = useQueryState("sort", nuqsSortParser.withDefault([{ id: "createdAt", desc: true }]));

  const toggleSortBy = (columnId: string, clearPrevious = false) => {
    setSortBy((prev) => {
      const existingSort = prev.find((sort) => sort.id === columnId);

      if (clearPrevious) {
        if (existingSort) return [{ id: columnId, desc: !existingSort.desc }];
        return [{ id: columnId, desc: true }];
      }

      if (existingSort) return prev.map((sort) => (sort.id === columnId ? { ...sort, desc: !sort.desc } : sort));
      return [...prev, { id: columnId, desc: true }];
    });
  };

  const {
    subscriberContactMethods,
    subscriberContactMethodsTotal,
    fetchMoreSubscriberContactMethods,
    isSubscriberContactMethodsFetching,
    isSubscriberContactMethodsLoading,
  } = useInfiniteNotifySubscriberContactMethods({
    take: 100,
    query: filters.query,
    status: filters.status,
    subscriptionGroupIds: filters.subscriptionGroups,
    types: filters.types,
    sort: sortBy.map(({ id, desc }) => ({ column: id, direction: desc ? "desc" : "asc" })),
  });

  const { sendBulkInvite, isSendBulkInviteLoading } = useNotifyBulkInviteCreate({
    onSuccess: () => {
      addToast("Invitations sent", { appearance: "success", autoDismiss: true });
      clearBulkSelections();
    },
    onError: () => {
      addToast("Invitations failed to send", { appearance: "error", autoDismiss: true });
    },
  });

  const {
    bulkSelections,
    onBulkSelectAll,
    onBulkSelect,
    shouldBulkSelectBeChecked,
    shouldBulkSelectAllBeChecked,
    shouldBulkSelectAllBeIndeterminate,
    clearBulkSelections,
    findSelectedIdsFromLoadedData,
  } = useBulkSelect({ totalCount: subscriberContactMethodsTotal });

  const {
    openWithId: openSubscriberDetailFlyoutWithId,
    close: closeSubscriberDetailFlyout,
    id: subscriberDetailFlyoutId,
  } = useFlyout();
  const { open: openInviteFlyout, close: closeInviteFlyout, isOpen: isInviteFlyoutOpen } = useFlyout();

  useEffect(() => {
    clearBulkSelections();
  }, [filters.status]);

  const subscriberContactMethodsById = useMemo(
    () =>
      subscriberContactMethods.reduce(
        (acc, contact) => ({ ...acc, [contact.id]: contact }),
        {} as Record<number, GetNotifySubscriberContactMethodsResponse["data"][number]>,
      ),
    [subscriberContactMethods],
  );
  const selectedContactMethodIds = useMemo(
    () => findSelectedIdsFromLoadedData(subscriberContactMethods, (contact) => contact.id),
    [findSelectedIdsFromLoadedData, subscriberContactMethods],
  );
  const selectedContactMethods = useMemo(
    () => selectedContactMethodIds.map((id) => subscriberContactMethodsById[id]).filter(Boolean),
    [selectedContactMethodIds],
  );

  // useMemo is required here
  const columns = useMemo<ReactTableColumn<(typeof subscriberContactMethods)[0]>[]>(
    () => [
      {
        accessor: "id",
        Header: ({}) => (
          <Checkbox
            indeterminate={shouldBulkSelectAllBeIndeterminate()}
            checked={shouldBulkSelectAllBeChecked()}
            onChange={onBulkSelectAll}
          />
        ),
        Cell: ({ value }) => (
          <Checkbox
            checked={shouldBulkSelectBeChecked(value)}
            onChange={(e) => {
              e.stopPropagation();
              onBulkSelect(value);
            }}
          />
        ),
        disableSortBy: true,
        width: 40,
      },
      {
        accessor: "type",
        Header: "Type",
        Cell: ({ value }) => {
          const iconLookup: Record<string, Function> = {
            Email: () => <FontAwesomeIcon className='tw-text-purple' icon={faEnvelope} />,
            Phone: () => <FontAwesomeIcon className='tw-text-orange' icon={faPhone} />,
            App: () => <FontAwesomeIcon className='tw-text-teal' icon={faMobile} />,
          };
          return (
            <div className='tw-space-x-3 tw-whitespace-nowrap'>
              {iconLookup[value]()}
              <span>{value}</span>
            </div>
          );
        },
        width: 100,
      },
      {
        accessor: "identifier",
        Header: filters.status === "Invited" ? "Phone / Email" : "Phone / Email / App",
        Cell: ({ value, row }) => {
          if (row.original.type === "Phone")
            return (
              <span className='tw-flex tw-items-center tw-gap-2 tw-overflow-hidden tw-whitespace-nowrap'>
                <ParsedPhoneNumber value={value} />
                {row.original.isPhoneNumberSMSCapable === false && (
                  <Popup
                    content='This number cannot receive SMS messages'
                    trigger={<FontAwesomeIcon icon={faPhoneVolume} />}
                    inverted
                    position='top left'
                  />
                )}
              </span>
            );
          return <OverflowTextTooltip className='tw-whitespace-nowrap' text={value}></OverflowTextTooltip>;
        },
        minWidth: 200,
      },
      {
        accessor: "person",
        Header: "Contact Match",
        Cell: ({ value, row }) => {
          if (value) {
            return (
              <ContactConnectedProfilePopup
                personId={value.personId}
                trigger={
                  <div className='tw-flex tw-items-center tw-gap-3'>
                    <ContactAvatar firstName={value.firstName ?? ""} lastName={value.lastName ?? ""} />
                    <div>
                      {value.firstName} {value.lastName}
                    </div>
                  </div>
                }
              />
            );
          }
          const potentialMatchNames = row.original.potentialPersonMatches.map((match) =>
            [match.firstName, match.lastName].join(" "),
          );
          if (potentialMatchNames.length) {
            return <OverflowTextTooltip className='tw-whitespace-nowrap' text={potentialMatchNames.join(", ")} />;
          }

          return null;
        },
        minWidth: 200,
      },
      {
        accessor: "origin",
        Header: "Origin",
        Cell: ({ value }) => <div>{value}</div>,
        width: 120,
      },
      {
        accessor: "invitedAt",
        Header: "Invited",
        Cell: ({ value }) => (
          <Popup
            content={<ZonedDateTime value={value} />}
            trigger={
              !!value ? (
                <div className='tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap'>
                  {formatDistanceToNow(value)} ago
                </div>
              ) : null
            }
            inverted
            position='top left'
          ></Popup>
        ),
        minWidth: 100,
      },
      {
        accessor: "invitedMethod",
        Header: "Method",
        Cell: ({ value }) => <div>{value}</div>,
        width: 100,
      },
      {
        accessor: "createdAt",
        Header: "Joined",
        Cell: ({ value }) => (
          <Popup
            content={<ZonedDateTime value={value} />}
            trigger={
              <div className='tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap'>
                {formatDistanceToNow(value)} ago
              </div>
            }
            inverted
            position='top left'
          ></Popup>
        ),
        minWidth: 120,
      },
      {
        accessor: "optedOutAt",
        Header: "Opted-Out",
        Cell: ({ value }) => (
          <Popup
            content={<ZonedDateTime value={value} />}
            trigger={
              value ? (
                <div className='tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap'>
                  {formatDistanceToNow(value)} ago
                </div>
              ) : null
            }
            inverted
            position='top left'
          ></Popup>
        ),
      },
      {
        accessor: "optedOutMethod",
        Header: "Method",
        Cell: ({ value }) => <div>{value}</div>,
        width: 100,
      },
      {
        accessor: "lastCommunicationAttemptedAt",
        Header: "Last Sent",
        Cell: ({ value }) => (
          <Popup
            content={<ZonedDateTime value={value} />}
            trigger={
              value ? (
                <div className='tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap'>
                  {formatDistanceToNow(value)} ago
                </div>
              ) : null
            }
            inverted
            position='top left'
          ></Popup>
        ),
        minWidth: 120,
      },
      {
        accessor: "subscriptionGroups",
        Header: "Subscription Groups",
        Cell: ({ value, row }) => {
          const subscriptionGroupNames = value
            .filter((subscriptionGroup) => !subscriptionGroup.autoSubscribe)
            .map((subscriptionGroup) => subscriptionGroup.name);

          const content = (
            <>
              {row.original.hasAllAutoSubscriptionGroups && (
                <span className='tw-italic'>All auto subscribe groups</span>
              )}
              <span>
                {(subscriptionGroupNames.length && row.original.hasAllAutoSubscriptionGroups ? " · " : " ") +
                  subscriptionGroupNames.join(" · ")}
              </span>
            </>
          );

          return (
            <Popup
              content={<div>{content}</div>}
              inverted
              mouseEnterDelay={500}
              mouseLeaveDelay={200}
              on='hover'
              trigger={<div className='tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap'>{content}</div>}
            />
          );
        },
        width: 500,
        disableSortBy: true,
      },
    ],
    [bulkSelections, filters.status],
  );

  const { rows, prepareRow, headers, setHiddenColumns, setColumnOrder } = useTable(
    {
      columns,
      data: subscriberContactMethods,
      useControlledState: (state) => {
        return useMemo(
          () => ({
            ...state,
            sortBy,
          }),
          [state, sortBy],
        );
      },
      manualSortBy: true,
    },
    useSortBy,
    useColumnOrder,
  );

  const totalWidth = headers.reduce((acc, column) => {
    if (!column.isVisible) return acc;
    if (column.width) return Number(column.width) + acc;
    return column.minWidth ?? acc;
  }, 0);

  useEffect(() => {
    if (filters.status === "Enrolled") {
      setHiddenColumns(["origin", "invitedAt", "invitedMethod", "optedOutAt", "optedOutMethod"]);
      setColumnOrder([
        "id",
        "type",
        "identifier",
        "person",
        "createdAt",
        "lastCommunicationAttemptedAt",
        "subscriptionGroups",
      ]);
    }
    if (filters.status === "Invited") {
      setHiddenColumns(["createdAt", "lastCommunicationAttemptedAt", "optedOutAt", "optedOutMethod"]);
      setColumnOrder([
        "id",
        "type",
        "identifier",
        "person",
        "origin",
        "invitedAt",
        "invitedMethod",
        "subscriptionGroups",
      ]);
    }
    if (filters.status === "OptedOut") {
      setHiddenColumns(["origin", "invitedAt", "invitedMethod", "lastCommunicationAttemptedAt"]);
      setColumnOrder([
        "id",
        "type",
        "identifier",
        "person",
        "createdAt",
        "optedOutAt",
        "optedOutMethod",
        "subscriptionGroups",
      ]);
    }
  }, [setHiddenColumns, setColumnOrder, filters.status, bulkSelections]);

  const getFoundTextUnit = () => {
    if (filters.status === "Enrolled") {
      return pluralize(subscriberContactMethodsTotal, "subscription", "subscriptions");
    }
    if (filters.status === "Invited") {
      return pluralize(subscriberContactMethodsTotal, "invitation", "invitations");
    }
    if (filters.status === "OptedOut") {
      return pluralize(subscriberContactMethodsTotal, "opt-out", "opt-outs");
    }
    return "";
  };

  return (
    <>
      <SubscriberDetailFlyout
        view={filters.status}
        subscriberId={subscriberDetailFlyoutId}
        onClose={closeSubscriberDetailFlyout}
      />
      <InviteSubscribersFlyout
        onClose={closeInviteFlyout}
        open={isInviteFlyoutOpen}
        recipients={selectedContactMethods
          .filter((contact) => contact?.type !== "App")
          .map((contact) => ({
            type: contact.type as "Email" | "Phone",
            value: contact.identifier,
          }))}
        onSubmit={clearBulkSelections}
      />
      <Segment attached='top' className='subText tw-flex tw-flex-col tw-gap-3 tw-px-[11px] tw-py-2 md:tw-flex-row'>
        {isSubscriberContactMethodsLoading ? (
          <span>Loading...</span>
        ) : (
          <span className='tw-flex tw-items-center tw-gap-2'>
            {!!selectedContactMethodIds.length ? (
              <span>{selectedContactMethodIds.length} selected</span>
            ) : (
              <span>
                Found {subscriberContactMethodsTotal} {getFoundTextUnit()}
              </span>
            )}
            <Loader inline active={isSubscriberContactMethodsFetching} size='tiny' />
          </span>
        )}
        <div className='tw-flex tw-flex-col tw-gap-1 sm:tw-flex-row sm:tw-gap-3'>
          {!!selectedContactMethodIds.length && filters.status === "Enrolled" && (
            <>
              <AddSubscribersToSubscriptionGroupsPopup
                subscriberIds={selectedContactMethodIds}
                subscriberContactMethodsById={subscriberContactMethodsById}
                onSubmit={clearBulkSelections}
              >
                <Button className='tw-m-0' content='Add to Groups' secondary />
              </AddSubscribersToSubscriptionGroupsPopup>
              <RemoveSubscribersFromSubscriptionGroupsPopup
                subscriberIds={selectedContactMethodIds}
                onSubmit={clearBulkSelections}
              >
                <Button className='tw-m-0' content='Remove from Groups' secondary />
              </RemoveSubscribersFromSubscriptionGroupsPopup>
              <OptOutSubscribersPopup subscriberIds={selectedContactMethodIds} onSubmit={clearBulkSelections}>
                <Button className='tw-m-0' content='Opt-out' secondary />
              </OptOutSubscribersPopup>
            </>
          )}
          {!!selectedContactMethodIds.length && filters.status === "Invited" && (
            <>
              <Button
                className='tw-m-0'
                content='Re-Send'
                secondary
                onClick={async () => {
                  await sendBulkInvite({
                    options: {
                      resendInvitations: true,
                      autoEnroll: null,
                      resendOptedOut: true,
                    },
                    recipients: selectedContactMethods
                      .filter((contact) => contact.type === "Email" || contact.type === "Phone")
                      .map((contact) => ({
                        type: contact.type as "Email" | "Phone",
                        identifier: contact.identifier,
                      })),
                    subscriptionPreferences: [],
                  });
                }}
                disabled={isSendBulkInviteLoading}
                loading={isSendBulkInviteLoading}
              />
              <OptInSubscribersPopup
                subscriberContactMethodIds={selectedContactMethodIds}
                onSubmit={clearBulkSelections}
              >
                <Button className='tw-m-0' content='Opt-In' secondary />
              </OptInSubscribersPopup>
            </>
          )}
          {!!selectedContactMethodIds.length && filters.status === "OptedOut" && (
            <Button className='tw-m-0' content='Invite' secondary onClick={openInviteFlyout} />
          )}
        </div>
      </Segment>
      <Segment
        attached='bottom'
        className='notify-subscribers-infinite-table tw-h-full tw-overflow-x-auto tw-overflow-y-hidden tw-p-0'
      >
        <InfiniteLoader
          isRowLoaded={({ index }) => !!rows[index]}
          loadMoreRows={async () => fetchMoreSubscriberContactMethods()}
          rowCount={subscriberContactMethodsTotal}
        >
          {({ onRowsRendered, registerChild }) => (
            <AutoSizer>
              {({ width, height }) => (
                <Table
                  headerHeight={45}
                  rowCount={rows.length}
                  rowHeight={45}
                  ref={registerChild}
                  width={Math.max(width - 2, totalWidth)} // Magic 2 to account for border
                  height={height}
                  onRowsRendered={onRowsRendered}
                  rowGetter={({ index }) => {
                    const row = rows[index];
                    prepareRow(row);
                    return row;
                  }}
                  style={{ minWidth: `${Math.max(width - 2, totalWidth)}px` }} // Magic 2 to account for border
                  onRowClick={({ rowData }) => {
                    openSubscriberDetailFlyoutWithId(rowData.original.id);
                  }}
                >
                  {headers
                    .filter((column) => column.isVisible)
                    .map((column) => (
                      <Column
                        key={column.id}
                        dataKey={column.id}
                        maxWidth={column.maxWidth}
                        minWidth={column.minWidth}
                        flexGrow={(column as any).flexGrow}
                        flexShrink={(column as any).flexShrink}
                        width={typeof column.width === "number" ? column.width : 150}
                        headerClassName={classNames({
                          "tw-cursor-pointer": column.canSort,
                        })}
                        headerRenderer={() => (
                          <div
                            role='button'
                            tabIndex={-1}
                            onClick={() => {
                              if (column.canSort) {
                                toggleSortBy(column.id, true);
                              }
                            }}
                          >
                            {column.render("Header")}
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <Icon name='caret down' />
                              ) : (
                                <Icon name='caret up' />
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        )}
                        cellRenderer={({ rowIndex, columnIndex }) => {
                          return rows[rowIndex].cells[columnIndex].render("Cell");
                        }}
                      />
                    ))}
                </Table>
              )}
            </AutoSizer>
          )}
        </InfiniteLoader>
      </Segment>
    </>
  );
}
