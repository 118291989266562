import { useMutation, useQueryCache } from "react-query";
import useNetworkHeaders from "../../../hooks/useNetworkHeaders";
import { putNotifySubscriptionPreferences } from "../../../queries/notify";
import { buildNotifySubscriberContactMethodsQueryKey } from "./useInfiniteNotifySubscriberContactMethods";
import { buildNotifySubscriberContactMethodQueryKey } from "./useNotifySubscriberContactMethod";

export function useNotifySubscriptionPreferencesUpdate() {
  const networkHeaders = useNetworkHeaders();
  const queryCache = useQueryCache();

  const [editSubscriptionPreferencesMutation, { isLoading: isEditSubscriptionPreferencesLoading }] = useMutation(
    putNotifySubscriptionPreferences(networkHeaders),
    {
      onSuccess: async (_, { subscriberContactMethodIds }) => {
        await Promise.all(
          subscriberContactMethodIds.map((subscriberContactMethodId) => {
            return queryCache.invalidateQueries(
              buildNotifySubscriberContactMethodQueryKey({ id: subscriberContactMethodId }),
            );
          }),
        );
        await queryCache.invalidateQueries(buildNotifySubscriberContactMethodsQueryKey());
      },
    },
  );

  const editSubscriptionPreferences = async ({
    subscriberContactMethodIds,
    subscriptionGroupIds,
    type,
    overwriteExistingPreference = false,
  }: {
    subscriberContactMethodIds: number[];
    subscriptionGroupIds: number[];
    type: "Subscribe" | "Unsubscribe";
    overwriteExistingPreference?: boolean;
  }) => {
    return await editSubscriptionPreferencesMutation({
      subscriberContactMethodIds,
      subscriptionGroupIds,
      type,
      overwriteExistingPreference,
    });
  };

  return { editSubscriptionPreferences, isEditSubscriptionPreferencesLoading };
}
