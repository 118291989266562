import { useQuery } from "react-query";
import { getNotifySubscriberContactMethod } from "../../../queries/notify";
import useNetworkHeaders from "../../../hooks/useNetworkHeaders";

export function useNotifySubscriberContactMethod({ id }: { id?: number | null }) {
  const networkHeaders = useNetworkHeaders();

  const { data: subscriberContactMethod, isLoading: isSubscriberContactMethodLoading } = useQuery(
    buildNotifySubscriberContactMethodQueryKey({ id }),
    getNotifySubscriberContactMethod(networkHeaders),
    {
      queryFnParamsFilter(args) {
        return [args[1]];
      },
      enabled: !!id,
    },
  );

  return {
    subscriberContactMethod,
    isSubscriberContactMethodLoading,
  };
}

export function buildNotifySubscriberContactMethodQueryKey(params: { id?: number | null }) {
  return ["notifySubscriberContactMethod", params];
}
