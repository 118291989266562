import React, { PropsWithChildren, useState } from "react";
import { Button, Form, Popup } from "semantic-ui-react";
import { ControlledInput } from "../../../../../../components/ControlledForm";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import { useNotifySubscriberContactMethodOptIn } from "../../../../hooks/useNotifySubscriberContactMethodOptIn";

export function OptInSubscribersPopup({
  children,
  subscriberContactMethodIds,
  onSubmit = () => {},
}: PropsWithChildren<{
  subscriberContactMethodIds: number[];
  onSubmit?: () => void;
}>) {
  const { addToast } = useToasts();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { control, errors, handleSubmit } = useForm({
    defaultValues: {
      justification: "",
    },
  });

  const { DANGER_optInSubscriberContactMethod, isOptInSubscriberContactMethodLoading } =
    useNotifySubscriberContactMethodOptIn();

  return (
    <Popup
      trigger={children}
      open={isPopupOpen}
      position='bottom left'
      className='tw-w-96 tw-p-5 md:tw-w-[400px]'
      flowing
      on='click'
      onOpen={() => setIsPopupOpen(true)}
      onClose={() => setIsPopupOpen(false)}
    >
      <Popup.Header className='tw-flex tw-flex-col'>
        <span>Opt-In?</span>
        <span className='subText tw-text-base tw-font-medium'>{subscriberContactMethodIds.length} subscribers</span>
      </Popup.Header>
      <Popup.Content className='tw-flex tw-flex-col tw-gap-3'>
        <Form>
          <Form.Field required error={!!errors?.justification}>
            <ControlledInput
              control={control}
              name='justification'
              label='Justification For Auto-Enrollment'
              subLabel='For regulatory purposes, explain why you are bypassing the enrollment process.'
              rules={{
                minLength: {
                  value: 10,
                  message: "Justification must be at least 10 characters.",
                },
              }}
              required
              errors={errors}
            />
          </Form.Field>
        </Form>
        <div className='tw-flex tw-justify-end tw-gap-2'>
          <Button className='tw-m-0' secondary content='Cancel' onClick={() => setIsPopupOpen(false)} />
          <Button
            className='tw-m-0'
            color='red'
            content='Opt-In'
            disabled={isOptInSubscriberContactMethodLoading}
            loading={isOptInSubscriberContactMethodLoading}
            onClick={handleSubmit(async ({ justification }) => {
              await DANGER_optInSubscriberContactMethod({
                subscriberContactMethodIds,
                justification,
              });

              addToast("Subscribers opted-in", { appearance: "success", autoDismiss: true });
              setIsPopupOpen(false);
              onSubmit();
            })}
          />
        </div>
      </Popup.Content>
    </Popup>
  );
}
