import React from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import { buildIdentifierString } from "../utils/buildIdentifierString";
import { useToasts } from "react-toast-notifications";
import { useNotifySubscriberContactMethod } from "../../../../hooks/useNotifySubscriberContactMethod";
import { useForm } from "react-hook-form";
import { ControlledInput } from "../../../../../../components/ControlledForm";
import { useNotifySubscriberContactMethodOptIn } from "../../../../hooks/useNotifySubscriberContactMethodOptIn";

export function OptInSubscriberModal({
  subscriberContactMethodId,
  isOpen,
  close,
  onSubmit = () => {},
}: {
  subscriberContactMethodId?: number;
  isOpen: boolean;
  close: () => void;
  onSubmit?: () => void;
}) {
  const { addToast } = useToasts();
  const { control, errors, handleSubmit } = useForm({
    defaultValues: {
      justification: "",
    },
  });

  const { subscriberContactMethod } = useNotifySubscriberContactMethod({
    id: subscriberContactMethodId,
  });

  const { DANGER_optInSubscriberContactMethod, isOptInSubscriberContactMethodLoading } =
    useNotifySubscriberContactMethodOptIn();

  return (
    <Modal open={isOpen} onClose={close} closeIcon size='tiny'>
      <Modal.Header>Opt-in {buildIdentifierString(subscriberContactMethod)}?</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field required error={!!errors?.justification}>
            <ControlledInput
              control={control}
              name='justification'
              label='Justification For Auto-Enrollment'
              subLabel='For regulatory purposes, explain why you are bypassing the enrollment process.'
              rules={{
                minLength: {
                  value: 10,
                  message: "Justification must be at least 10 characters.",
                },
              }}
              required
              errors={errors}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button content='Cancel' secondary onClick={close} />
        <Button
          content='Opt-in'
          negative
          disabled={isOptInSubscriberContactMethodLoading}
          loading={isOptInSubscriberContactMethodLoading}
          onClick={handleSubmit(async ({ justification }) => {
            if (!subscriberContactMethod) return;

            await DANGER_optInSubscriberContactMethod({
              subscriberContactMethodIds: [subscriberContactMethod.id],
              justification,
            });

            addToast("Subscriber opted-in", { appearance: "success", autoDismiss: true });
            close();
            onSubmit();
          })}
        />
      </Modal.Actions>
    </Modal>
  );
}
