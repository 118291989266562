import { combineReducers } from "redux";
import { LOCATION_CHANGE } from "connected-react-router";
import { createReducer, createResourceReducer } from "./create";
import * as I from "../actions/identity";
import { setAccessToken } from "../lib/auth";

const postResetPassword = createReducer(
  I.POST_RESET_PASSWORD_LOADING,
  I.POST_RESET_PASSWORD_SUCCESS,
  I.POST_RESET_PASSWORD_FAILURE,
);
const postVerify = createReducer(I.POST_VERIFY_LOADING, I.POST_VERIFY_SUCCESS, I.POST_VERIFY_FAILURE);
const postForgotPassword = createReducer(
  I.POST_FORGOT_PASSWORD_LOADING,
  I.POST_FORGOT_PASSWORD_SUCCESS,
  I.POST_FORGOT_PASSWORD_FAILURE,
);
const postLogin = createReducer(I.POST_LOGIN_LOADING, I.POST_LOGIN_SUCCESS, I.POST_LOGIN_FAILURE);
const postSignup = createReducer(I.POST_SIGNUP_LOADING, I.POST_SIGNUP_SUCCESS, I.POST_SIGNUP_FAILURE);
const postSendWelcome = createReducer(
  I.POST_SEND_WELCOME_LOADING,
  I.POST_SEND_WELCOME_SUCCESS,
  I.POST_SEND_WELCOME_FAILURE,
);
const putProfile = createReducer(I.PUT_PROFILE_LOADING, I.PUT_PROFILE_SUCCESS, I.PUT_PROFILE_FAILURE);
const getLoginHistory = createResourceReducer(
  I.GET_LOGIN_HISTORY_LOADING,
  I.GET_LOGIN_HISTORY_SUCCESS,
  I.GET_LOGIN_HISTORY_FAILURE,
  I.GET_LOGIN_HISTORY_CLEAR,
);

const reducers = combineReducers({
  postResetPassword,
  postForgotPassword,
  postSendWelcome,
  postLogin,
  postSignup,
  postVerify,
  putProfile,
  getLoginHistory,
});

function rootReducer(state: any, action: any) {
  switch (action.type) {
    case LOCATION_CHANGE:
      if (action.payload.location.pathname.endsWith("/forgot")) {
        return Object.assign({}, state, { postForgotPassword: {}, postLogin: {} });
      }
      return reducers(state, action);
    case I.JWT_UPDATED:
      const { accessToken } = action.payload;
      setAccessToken(accessToken);
      return Object.assign({}, state, {
        jwt: action.payload,
      });
    default:
      return reducers(state, action);
  }
}

export default rootReducer;
