import React from "react";
import { Placeholder } from "semantic-ui-react";
import { useDebounce } from "../../hooks/useDebounce";

export default function PlaceholderUntilLoaded({
  children,
  isLoading,
  lineCount = 1,
  minimumShowTime = 0,
  className,
}: React.PropsWithChildren<{ isLoading: boolean; lineCount?: number; minimumShowTime?: number; className?: string }>) {
  const debouncedIsLoading = useDebounce(isLoading, minimumShowTime);
  return (
    <>
      {debouncedIsLoading && (
        <Placeholder fluid className={className}>
          {Array(lineCount)
            .fill(0)
            .map((_, index) => (
              <Placeholder.Line length='full' key={index} />
            ))}
        </Placeholder>
      )}
      {!debouncedIsLoading && children}
    </>
  );
}
