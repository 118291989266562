import React from "react";
import { Controller } from "react-hook-form";
import { Form, Icon, Label } from "semantic-ui-react";
import CurrencyFormat from "react-currency-format";
import "./ControlledCurrency.scss";
import { fieldError } from "../utils/functions";

const Readonly = ({ as, value }: any) => {
  const isEmpty = [undefined, ""].includes(value);
  return React.createElement(as, !isEmpty ? {} : { style: { color: "gray" } }, [isEmpty ? "N/A" : value]);
};

interface IControlledCurrency {
  name: string;
  control: any;
  label?: any;
  rules?: any;
  defaultValue?: any;
  readonly?: boolean;
  required?: boolean;
  errors?: any;
  transform?: { input: (value: any) => string | null | undefined; output: (value: string | null | undefined) => any };
  /**
   * Must be in the range 0-20 inclusive. Defaults to 2.
   * @see Number.toFixed
   */
  precision?: number;
}

export const ControlledCurrency = ({
  control,
  defaultValue,
  name,
  label,
  rules,
  errors,
  readonly = false,
  transform = {
    input: (value) => value,
    output: (value) => value,
  },
  precision = 2,
}: IControlledCurrency) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ onChange, value, name }) => {
        return (
          <UncontrolledCurrency
            value={value}
            defaultValue={defaultValue}
            label={label}
            name={name}
            readonly={readonly}
            errors={errors}
            onChange={onChange}
            precision={precision}
            transform={transform}
          />
        );
      }}
    />
  );
};

interface UncontrolledCurrencyProps<T = string | null | undefined> {
  value: string | null | undefined;
  defaultValue?: string;
  name: string;
  label?: string;
  readonly?: boolean;
  errors?: any;
  /**
   * Must be in the range 0-20 inclusive. Defaults to 2.
   * @see Number.toFixed
   */
  precision?: number;
  onChange: (value: T) => void;
  transform?: { input: (value: T) => string | null | undefined; output: (value: string | null | undefined) => T };
}
export const UncontrolledCurrency = ({
  value,
  defaultValue,
  label,
  name,
  readonly = false,
  errors,
  onChange,
  precision = 2,
  transform = {
    input: (value) => value,
    output: (value) => value,
  },
}: UncontrolledCurrencyProps) => {
  return (
    <>
      {label && <label htmlFor={name}>{label}</label>}
      {readonly && <Readonly as='span' value={transform.input(value || defaultValue)} />}
      {!readonly && (
        <Form.Input iconPosition='left' placeholder='' className='controlled-currency'>
          <Icon name='dollar sign' />
          <CurrencyFormat
            id={name}
            name={name}
            value={!isNaN(parseFloat(transform.input(value) ?? "")) ? transform.input(value) : ""}
            displayType={"input"}
            thousandSeparator={true}
            allowNegative={false}
            format={(value: any) => (parseFloat(value) / Math.pow(10, precision)).toFixed(precision)}
            onValueChange={({ value }: any) => onChange(transform.output(value))}
          />
        </Form.Input>
      )}
      {!!fieldError(errors, name) && (
        <Label basic prompt pointing='above'>
          {fieldError(errors, name)?.content?.message}
        </Label>
      )}
    </>
  );
};
