import jwtDecode from "jwt-decode";
import _ from "lodash";
import Cookies from "js-cookie";

export function setAccessToken(token: string, site?: string) {
  const payload = jwtDecode(token);
  const exp = _.get(payload, "exp", 0) * 1000;
  const expires = new Date(exp);
  const domain = getHostName();
  const pathSite = site ?? getSite();
  Cookies.set("jwt.access_token", token, { domain, path: `/${pathSite}`, expires, secure: true, sameSite: "Strict" });
}

export function removeAccessToken() {
  const domain = getHostName();
  const site = getSite();
  Cookies.remove("jwt.access_token", { domain, path: `/${site}`, secure: true, sameSite: "Strict" });
  // Remove any root domain cookies too
  Cookies.remove("jwt.access_token", { domain, path: `/`, secure: true, sameSite: "Strict" });
}

export function getAccessToken() {
  const cookie = Cookies.get("jwt.access_token");
  if (!cookie || cookie.length === 0) return null;
  return cookie;
}

function getHostName() {
  return window.location.hostname === "localhost" ? "" : `.${window.location.hostname.split(".").slice(-2).join(".")}`;
}

function getSite() {
  return window.location.pathname.split("/")[1];
}

export function checkSiteMatch(site: string | null | undefined) {
  return getSite() === site;
}
