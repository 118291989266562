import { AnyAction, Dispatch } from "redux";
import { history, isInternal, isAuthenticatingRequest } from "../lib/network";
import { pick } from "lodash";

/**
 * Middleware to globally handle network errors from internal API calls.
 * 
 * Define custom handlers by adding them to the meta object of the action types. Example:
 * ```
 *  createAction({
      endpoint: `/some/endpoint`,
      method: "GET",
      headers: networkSelectors.defaultHeaders,
      types: [
        {
          type: GET_IDENTITY_LOADING,
          meta: { onUnauthorized: () => {...} },
        }, ...],
    });
 */
export const checkAuthMiddleware =
  ({ getState }: { getState: any }) =>
  (next: Dispatch<AnyAction>) =>
  (action: AnyAction) => {
    if (!action.meta) return next(action);
    const state = getState();
    const isGogovCall = action.meta.endpoint && isInternal(action.meta.endpoint);
    const isUnauthorized = !!action.error && action.payload.status === 401;
    const isForbidden = !!action.error && action.payload.status === 403;
    const isNotFound = !!action.error && action.payload.status === 404;
    if (isGogovCall) {
      if (isUnauthorized && !isAuthenticatingRequest(action.meta.endpoint)) {
        if (action.meta.onUnauthorized) action.meta.onUnauthorized();
        else history.push(`/${state.site.site}/kickout?redirect=${state.router.location.pathname}`);
      } else if (isForbidden) {
        if (action.meta.onForbidden) action.meta.onForbidden();
      } else if (isNotFound) {
        if (action.meta.onNotFound) action.meta.onNotFound();
      }
    }
    return next(action);
  };

/**
 * Add the endpoint data to the meta object of the action types so that the checkAuthMiddleware can use it.
 * Can add more meta data if needed, but for now only need the endpoint.
 */
export const addMetaMiddleware = () => (next: Dispatch<AnyAction>) => (action: AnyAction) => {
  const actionArgs = action?.["@@redux-api-middleware/RSAA"];
  if (!actionArgs) return next(action);
  try {
    const metaToAdd = pick(actionArgs, ["endpoint"]);
    actionArgs.types.forEach((type: any, i: number) => {
      if (typeof type === "string") {
        actionArgs.types[i] = {
          type,
          meta: metaToAdd,
        };
      } else {
        type.meta = {
          ...type.meta,
          ...metaToAdd,
        };
      }
    });
  } catch (error) {
    console.error("Error in addMetaMiddleware:", error);
  }
  return next(action);
};
