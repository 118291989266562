import { useInfiniteQuery } from "react-query";
import useNetworkHeaders from "../../../hooks/useNetworkHeaders";
import {
  getNotifySubscriberContactMethods,
  GetNotifySubscriberContactMethodsParamsType,
} from "../../../queries/notify";
import { useMemo } from "react";

export function useInfiniteNotifySubscriberContactMethods(params: GetNotifySubscriberContactMethodsParamsType) {
  const networkHeaders = useNetworkHeaders();
  const {
    data,
    isLoading: isSubscriberContactMethodsLoading,
    isFetching: isSubscriberContactMethodsFetching,
    fetchMore: fetchMoreSubscriberContactMethods,
    canFetchMore: canFetchMoreSubscriberContactMethods,
  } = useInfiniteQuery(
    buildNotifySubscriberContactMethodsQueryKey(params),
    getNotifySubscriberContactMethods(networkHeaders),
    {
      queryFnParamsFilter(args) {
        return [args[1]];
      },
      getFetchMore: (lastPage) => lastPage.data.at(-1)?.cursor?.join(","),
      keepPreviousData: true,
    },
  );

  const subscriberContactMethods = useMemo(() => data?.flatMap(({ data }) => data) ?? [], [data]);
  const lastPage = data?.at(-1);

  return {
    subscriberContactMethodsTypeTotalCount: lastPage?.aggregations.totalTypeCount ?? 0,
    subscriberContactMethodsTypeCounts: lastPage?.aggregations.typeCounts ?? null,
    subscriberContactMethodsTotal: lastPage?.total ?? 0,
    subscriberContactMethods,
    isSubscriberContactMethodsLoading,
    isSubscriberContactMethodsFetching,
    fetchMoreSubscriberContactMethods,
    canFetchMoreSubscriberContactMethods,
  };
}

export function buildNotifySubscriberContactMethodsQueryKey(params?: GetNotifySubscriberContactMethodsParamsType) {
  if (!params) {
    return "useInfiniteNotifySubscriberContactMethods";
  }
  return ["useInfiniteNotifySubscriberContactMethods", params];
}
