import { parsedPhoneNumberAsString } from "../../../../../../components/global_components/form_components/ControlledPhoneNumber";
import { GetNotifySubscriberContactMethodsResponse } from "../../../../../../queries/notify";

export function buildIdentifierString(subscriberContactMethod?: {
  type: GetNotifySubscriberContactMethodsResponse["data"][0]["type"];
  identifier: string;
}) {
  if (!subscriberContactMethod) return null;
  if (subscriberContactMethod.type === "Phone") {
    return parsedPhoneNumberAsString(subscriberContactMethod.identifier);
  }
  return subscriberContactMethod.identifier;
}
