import React, { useEffect, useState } from "react";
import { faLock } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { chain, groupBy, partition, uniqBy } from "lodash";
import { SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { Button, Divider, Form, Header, Popup, Table } from "semantic-ui-react";
import { ControlledCheckbox } from "../../../../../../components/ControlledForm";
import { CollapsibleSection } from "../../../../../../components/global_components/CollapsibleSection";
import { Flyout } from "../../../../../../components/global_components/Flyout";
import PlaceholderUntilLoaded from "../../../../../../components/global_components/PlaceholderUntilLoaded";
import { ZonedDateTime } from "../../../../../../components/global_components/ZonedDateTime";
import Tooltip from "../../../../../../components/Tooltip";
import { GetNotifySubscriberContactMethodResponse } from "../../../../../../queries/notify";
import { useNotifySubscriberContactMethod } from "../../../../hooks/useNotifySubscriberContactMethod";
import { useNotifySubscriptionGroups } from "../../../../hooks/useNotifySubscriptionGroups";
import { useNotifySubscriptionPreferencesUpdate } from "../../../../hooks/useNotifySubscriptionPreferencesUpdate";
import { OptOutSubscriberModal } from "../modals/OptOutSubscriberModal";
import { NotifySubscribersInfiniteTableView } from "../NotifySubscribersInfiniteTable";
import { buildIdentifierString } from "../utils/buildIdentifierString";
import { OptInSubscriberModal } from "../modals/OptInSubscriberModal";
import { useNotifyBulkInviteCreate } from "../../../../hooks/useNotifyBulkInviteCreate";

type HistoryCategory = GetNotifySubscriberContactMethodResponse["history"][0]["category"];
type FormType = {
  manualSubscriptionGroups: { subscriptionGroupId: number; value: boolean }[];
  autoSubscriptionGroups: { subscriptionGroupId: number; value: boolean }[];
};

export function SubscriberDetailFlyout({
  view,
  subscriberId,
  onClose,
}: {
  view: NotifySubscribersInfiniteTableView;
  subscriberId?: number | null;
  onClose: () => void;
}) {
  const { addToast } = useToasts();
  const { site } = useParams<{ site: string }>();
  const { control, handleSubmit, setValue, getValues } = useForm<FormType>({
    defaultValues: {
      manualSubscriptionGroups: [],
      autoSubscriptionGroups: [],
    },
  });

  const [shouldShowMoreSendHistory, setShouldShowMoreSendHistory] = useState(false);
  const [shouldShowMoreAuditTrail, setShouldShowMoreAuditTrail] = useState(false);

  const [isOptOutModalOpen, setIsOptOutModalOpen] = useState(false);
  const [isOptInModalOpen, setIsOptInModalOpen] = useState(false);

  // Manual loading state management since the save buttons use the same hooks
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isSaveAndResendLoading, setIsSaveAndResendLoading] = useState(false);

  const { subscriberContactMethod, isSubscriberContactMethodLoading } = useNotifySubscriberContactMethod({
    id: subscriberId,
  });

  // Setup default values for the form based on the subscriber's current preferences
  useEffect(() => {
    if (!subscriberContactMethod) return;

    const formValues = getValues();

    const manualFormIndexByGroupId = new Map(
      formValues.manualSubscriptionGroups.map((value, index) => [value.subscriptionGroupId, index]),
    );
    const autoFormIndexByGroupId = new Map(
      formValues.autoSubscriptionGroups.map((value, index) => [value.subscriptionGroupId, index]),
    );

    const preferences = subscriberContactMethod.subscriptionPreferences;
    preferences.forEach((preference) => {
      if (preference.subscriptionGroup.autoSubscribe) {
        const index = autoFormIndexByGroupId.get(preference.subscriptionGroupId);
        if (index !== undefined) {
          setValue(`autoSubscriptionGroups.${index}`, {
            subscriptionGroupId: preference.subscriptionGroupId,
            value: preference.type === "Subscribe",
          });
        }
      } else {
        const index = manualFormIndexByGroupId.get(preference.subscriptionGroupId);
        if (index !== undefined) {
          setValue(`manualSubscriptionGroups.${index}`, {
            subscriptionGroupId: preference.subscriptionGroupId,
            value: preference.type === "Subscribe",
          });
        }
      }
    });
  }, [subscriberContactMethod]);

  const { subscriptionGroups, isSubscriptionGroupsLoading } = useNotifySubscriptionGroups({});
  const subscriptionGroupsById = groupBy(subscriptionGroups, "id");
  const [autoSubscriptionGroups, manualSubscriptionGroups] = partition(
    subscriptionGroups ?? [],
    (subscriptionGroup) => subscriptionGroup.autoSubscribe,
  );

  const { editSubscriptionPreferences } = useNotifySubscriptionPreferencesUpdate();

  const { sendBulkInvite } = useNotifyBulkInviteCreate({
    onError: () => {
      addToast("Failed to send invitations", { appearance: "error", autoDismiss: true });
    },
  });

  const buildAuditTrailCategoryString = (category: HistoryCategory) => {
    switch (category) {
      case "Invite":
        return "Invited";
      case "OptIn":
        return "Opted In";
      case "OptOut":
        return "Opted Out";
      case "Verify":
        return "Verified";
      case "Create":
        return "Created";
      case "SubscriptionGroupPreferenceUpdate":
        return "Subscription Group Preferences Updated";
      default:
        return category;
    }
  };

  const savePreferences: SubmitHandler<FormType> = async (data) => {
    if (!subscriberContactMethod) return;

    const manualSubscriptionGroupIds = data.manualSubscriptionGroups;
    const autoSubscriptionGroupIds = data.autoSubscriptionGroups;

    const getCurrentSubscriptionGroupIds = (groups: { id: number }[], defaultValue: boolean) => {
      return groups.map((group) => {
        const subscriberPreference = subscriberContactMethod.subscriptionPreferences.find(
          (preference) => preference.subscriptionGroupId === group.id,
        );
        if (!subscriberPreference) {
          return {
            subscriptionGroupId: group.id,
            value: defaultValue,
          };
        }
        return {
          subscriptionGroupId: group.id,
          value: subscriberPreference?.type === "Subscribe",
        };
      });
    };

    const currentManualSubscriptionGroupIds = getCurrentSubscriptionGroupIds(manualSubscriptionGroups, false);
    const currentAutoSubscriptionGroupIds = getCurrentSubscriptionGroupIds(autoSubscriptionGroups, true);

    // Find the differences between the form and the current preferences
    const [manualGroupsToSubscribe, manualGroupsToUnsubscribe] = chain(manualSubscriptionGroupIds)
      .zip(currentManualSubscriptionGroupIds)
      .reduce(
        (acc, [group, currentGroup]) => {
          if (!group || !currentGroup || group.value === currentGroup.value) return acc;
          return [...acc, group];
        },
        [] as { subscriptionGroupId: number; value: boolean }[],
      )
      .partition((group) => group.value)
      .value();

    const [autoGroupsToSubscribe, autoGroupsToUnsubscribe] = chain(autoSubscriptionGroupIds)
      .zip(currentAutoSubscriptionGroupIds)
      .reduce(
        (acc, [group, currentGroup]) => {
          if (!group || !currentGroup || group.value === currentGroup.value) return acc;
          return [...acc, group];
        },
        [] as { subscriptionGroupId: number; value: boolean }[],
      )
      .partition((group) => group.value)
      .value();

    const subscriptionGroupIdsToSubscribe = [...manualGroupsToSubscribe, ...autoGroupsToSubscribe].map(
      (group) => group.subscriptionGroupId,
    );

    const subscriptionGroupIdsToUnsubscribe = [...manualGroupsToUnsubscribe, ...autoGroupsToUnsubscribe].map(
      (group) => group.subscriptionGroupId,
    );

    // Send subscriptions
    if (subscriptionGroupIdsToSubscribe.length) {
      await editSubscriptionPreferences({
        subscriptionGroupIds: subscriptionGroupIdsToSubscribe,
        subscriberContactMethodIds: [subscriberContactMethod.id],
        type: "Subscribe",
        overwriteExistingPreference: true,
      });
    }

    // Send un-subscriptions
    if (subscriptionGroupIdsToUnsubscribe.length) {
      await editSubscriptionPreferences({
        subscriptionGroupIds: subscriptionGroupIdsToUnsubscribe,
        subscriberContactMethodIds: [subscriberContactMethod.id],
        type: "Unsubscribe",
        overwriteExistingPreference: true,
      });
    }
  };

  const notificationSendHistory = uniqBy(
    subscriberContactMethod?.communicationAttempts.filter(
      (communicationAttempt) => !!communicationAttempt.notification,
    ) ?? [],
    (communicationAttempt) => {
      return communicationAttempt.notification?.id;
    },
  );

  const onSave = async (data: FormType) => {
    setIsSaveLoading(true);
    await savePreferences(data);
    setIsSaveLoading(false);
    addToast("Subscription preferences saved", { appearance: "success", autoDismiss: true });
    onClose();
  };

  const onSaveAndResend = async (data: FormType) => {
    if (!subscriberContactMethod) return;

    setIsSaveAndResendLoading(true);
    await savePreferences(data);
    await sendBulkInvite({
      recipients: [
        {
          identifier: subscriberContactMethod.identifier,
          type: subscriberContactMethod.type as "Email" | "Phone",
        },
      ],
      options: {
        autoEnroll: null,
        resendInvitations: true,
        resendOptedOut: true,
      },
      subscriptionPreferences: [],
    });
    setIsSaveAndResendLoading(false);
    addToast("Subscription preferences saved and invitation sent", {
      appearance: "success",
      autoDismiss: true,
    });
    onClose();
  };

  const subscriberIsOptedOut = subscriberContactMethod?.status === "OptedOut";

  return (
    <>
      <OptOutSubscriberModal
        subscriberContactMethodId={subscriberContactMethod?.id}
        close={() => setIsOptOutModalOpen(false)}
        isOpen={isOptOutModalOpen}
        onSubmit={onClose}
      />
      <OptInSubscriberModal
        subscriberContactMethodId={subscriberContactMethod?.id}
        close={() => setIsOptInModalOpen(false)}
        isOpen={isOptInModalOpen}
        onSubmit={onClose}
      />
      <Flyout open={!!subscriberId} onClose={onClose} disableClose={isOptOutModalOpen || isOptInModalOpen}>
        <Flyout.Header>
          <PlaceholderUntilLoaded isLoading={isSubscriberContactMethodLoading} lineCount={1}>
            <Header className='tw-mb-0'>{buildIdentifierString(subscriberContactMethod)}</Header>
            {view === "Enrolled" && (
              <Button
                className='button-ghost negative-bright'
                content='Opt-out'
                onClick={() => setIsOptOutModalOpen(true)}
              />
            )}
            {(view === "Invited" || view === "OptedOut") && (
              <Button
                className='button-ghost negative-bright'
                content='Opt-in'
                onClick={() => setIsOptInModalOpen(true)}
              />
            )}
          </PlaceholderUntilLoaded>
        </Flyout.Header>
        {subscriberIsOptedOut && (
          <Flyout.Banner danger>
            <div className='tw-flex tw-w-full tw-justify-center'>
              <h5>This Subscriber Has Opted-Out</h5>
            </div>
          </Flyout.Banner>
        )}
        <Flyout.Body key={subscriberContactMethod?.identifier}>
          <PlaceholderUntilLoaded isLoading={isSubscriberContactMethodLoading} lineCount={2}>
            <CollapsibleSection title='Manual Subscription Groups'>
              <PlaceholderUntilLoaded isLoading={isSubscriptionGroupsLoading} lineCount={3}>
                {manualSubscriptionGroups.map((subscriptionGroup, index) => (
                  <Form.Field key={subscriptionGroup.id} className='tw-m-0  tw-flex tw-items-center tw-gap-2'>
                    <ControlledCheckbox
                      control={control}
                      name={`manualSubscriptionGroups.${index}`}
                      transform={{
                        input: (value) => value?.value ?? false,
                        output: (value) => ({ subscriptionGroupId: subscriptionGroup.id, value }),
                      }}
                      className='tw-m-0'
                      label={subscriptionGroup.name + ` (${subscriptionGroup.id})`}
                      defaultValue={{
                        subscriptionGroupId: subscriptionGroup.id,
                        value: false,
                      }}
                    />
                    {subscriptionGroup.visibility === "Private" && (
                      <Popup trigger={<FontAwesomeIcon icon={faLock} />} content='Private Group' inverted />
                    )}
                  </Form.Field>
                ))}
              </PlaceholderUntilLoaded>
            </CollapsibleSection>
            <CollapsibleSection title='Auto-Subscribed Groups'>
              <PlaceholderUntilLoaded isLoading={isSubscriptionGroupsLoading} lineCount={3}>
                {autoSubscriptionGroups.map((subscriptionGroup, index) => (
                  <Form.Field key={subscriptionGroup.id} className='tw-m-0  tw-flex tw-items-center tw-gap-2'>
                    <ControlledCheckbox
                      control={control}
                      name={`autoSubscriptionGroups.${index}`}
                      transform={{
                        input: (value) => value?.value ?? false,
                        output: (value) => ({ subscriptionGroupId: subscriptionGroup.id, value }),
                      }}
                      className='tw-m-0'
                      label={subscriptionGroup.name + ` (${subscriptionGroup.id})`}
                      defaultValue={{
                        subscriptionGroupId: subscriptionGroup.id,
                        value: true,
                      }}
                    />
                    {subscriptionGroup.visibility === "Private" && (
                      <Popup trigger={<FontAwesomeIcon icon={faLock} />} content='Private Group' inverted />
                    )}
                  </Form.Field>
                ))}
              </PlaceholderUntilLoaded>
            </CollapsibleSection>
            <CollapsibleSection title='Audit Trail'>
              {!subscriberContactMethod?.history.length && (
                <div className='tw-text-black-light'>No audit trail records</div>
              )}
              {subscriberContactMethod?.history
                .slice(0, shouldShowMoreAuditTrail ? subscriberContactMethod?.history.length : 5)
                .map((history) => {
                  const details = history.details ?? {};
                  const hasPreferenceChanges =
                    history.category === "SubscriptionGroupPreferenceUpdate" && details.preferenceChanges;
                  const hasJustification = history.category === "OptIn" && !!details.justification;
                  return (
                    <div key={history.id}>
                      <div className='tw-mt-1 tw-flex tw-justify-between'>
                        <span>
                          {buildAuditTrailCategoryString(history.category)}
                          <Tooltip>{history.event}</Tooltip>
                        </span>
                        <span className='tw-whitespace-nowrap tw-text-black-light'>
                          <ZonedDateTime value={history.createdAt} formatString='MMM d, yyyy' />
                        </span>
                      </div>
                      {hasPreferenceChanges && (
                        <Table celled basic size='small' compact unstackable className='tw-my-2 tw-shadow-none'>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell>Subscription Group</Table.HeaderCell>
                              <Table.HeaderCell>Previous</Table.HeaderCell>
                              <Table.HeaderCell>Current</Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {details.preferenceChanges.map((change: any, index: number) => {
                              const nextSubscriptionGroup =
                                subscriptionGroupsById[change.next.subscriptionGroupId]?.at(0);
                              return (
                                <Table.Row key={index}>
                                  <Table.Cell>{nextSubscriptionGroup?.name}</Table.Cell>
                                  <Table.Cell
                                    className={classNames({
                                      "tw-text-black-light": !change.prev?.type,
                                    })}
                                  >
                                    {change.prev?.type ?? "No Preference"}
                                  </Table.Cell>
                                  <Table.Cell>{change.next?.type}</Table.Cell>
                                </Table.Row>
                              );
                            })}
                          </Table.Body>
                        </Table>
                      )}
                      {hasJustification && (
                        <div className='tw-mb-1 tw-text-black-light'>{(history.details ?? {}).justification}</div>
                      )}
                      {!hasPreferenceChanges && <Divider className='tw-my-1' />}
                    </div>
                  );
                })}
              {(subscriberContactMethod?.history.length ?? 0) > 5 && (
                <Button
                  className='button-ghost'
                  size='tiny'
                  content={shouldShowMoreAuditTrail ? "Show Less" : "Show More"}
                  onClick={() => setShouldShowMoreAuditTrail((prev) => !prev)}
                />
              )}
            </CollapsibleSection>
            <CollapsibleSection title='Send History'>
              {!notificationSendHistory.length && <div className='tw-text-black-light'>No send history records</div>}
              {notificationSendHistory
                .slice(0, shouldShowMoreSendHistory ? notificationSendHistory.length : 5)
                .map((communicationAttempt) => {
                  if (!communicationAttempt.notification) return null;
                  return (
                    <div key={communicationAttempt.id}>
                      <div className='tw-mt-1 tw-flex tw-justify-between'>
                        <a
                          href={`/${site}/communications/edit/${communicationAttempt.notification.id}`}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {communicationAttempt.notification.headline}
                        </a>
                        <span className='tw-whitespace-nowrap tw-text-black-light'>
                          {/* Dec 17, 2025 */}
                          <ZonedDateTime value={communicationAttempt.createdAt} formatString='MMM d, yyyy' />
                        </span>
                      </div>
                      <Divider className='tw-my-1' />
                    </div>
                  );
                })}
              {(notificationSendHistory.length ?? 0) > 5 && (
                <Button
                  className='button-ghost'
                  size='tiny'
                  content={shouldShowMoreSendHistory ? "Show Less" : "Show More"}
                  onClick={() => setShouldShowMoreSendHistory((prev) => !prev)}
                />
              )}
            </CollapsibleSection>
          </PlaceholderUntilLoaded>
          <PlaceholderUntilLoaded isLoading={isSubscriberContactMethodLoading} lineCount={4} />
        </Flyout.Body>
        <Flyout.Footer>
          <div className='tw-flex tw-justify-between'>
            <Button className='button-ghost' content='Close' onClick={onClose} />
            <div className='tw-flex tw-gap-3'>
              {!!subscriberContactMethod &&
                subscriberContactMethod?.type !== "App" &&
                subscriberContactMethod?.status !== "Enrolled" && (
                  <Button
                    className='tw-m-0'
                    secondary
                    content='Save & Resend Invite'
                    onClick={handleSubmit(onSaveAndResend)}
                    disabled={isSaveAndResendLoading}
                    loading={isSaveAndResendLoading}
                  />
                )}
              <Button
                className='tw-m-0'
                primary
                content='Save'
                onClick={handleSubmit(onSave)}
                disabled={isSaveLoading}
                loading={isSaveLoading}
              />
            </div>
          </div>
        </Flyout.Footer>
      </Flyout>
    </>
  );
}
