import React, { PropsWithChildren, useState } from "react";
import { Button, Popup } from "semantic-ui-react";
import { useNotifySubscriberContactMethodOptOut } from "../../../../hooks/useNotifySubscriberContactMethodOptOut";
import { useToasts } from "react-toast-notifications";

export function OptOutSubscribersPopup({
  children,
  subscriberIds,
  onSubmit = () => {},
}: PropsWithChildren<{ subscriberIds: number[]; onSubmit?: () => void }>) {
  const { addToast } = useToasts();
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const { optOutSubscriberContactMethod, isOptOutSubscriberContactMethodLoading } =
    useNotifySubscriberContactMethodOptOut();

  return (
    <Popup
      trigger={children}
      open={isPopupOpen}
      position='bottom left'
      className='tw-w-96 tw-p-5 md:tw-w-[400px]'
      flowing
      on='click'
      onOpen={() => setIsPopupOpen(true)}
      onClose={() => setIsPopupOpen(false)}
    >
      <Popup.Header className='tw-flex tw-flex-col'>
        <span>Opt-out?</span>
        <span className='subText tw-text-base tw-font-medium'>{subscriberIds.length} subscribers</span>
      </Popup.Header>
      <Popup.Content className='tw-flex tw-flex-col tw-gap-3'>
        <p>
          They will be unsubscribed from all groups, and removed from this list. To rejoin, they will need to receive
          and accept a new invite.{" "}
        </p>
        <div className='tw-flex tw-justify-end tw-gap-2'>
          <Button className='tw-m-0' secondary content='Cancel' onClick={() => setIsPopupOpen(false)} />
          <Button
            className='tw-m-0'
            color='red'
            content='Opt-out'
            disabled={isOptOutSubscriberContactMethodLoading}
            loading={isOptOutSubscriberContactMethodLoading}
            onClick={async () => {
              await optOutSubscriberContactMethod({
                subscriberContactMethodIds: subscriberIds,
              });
              addToast("Subscribers opted-out", { appearance: "success" });
              setIsPopupOpen(false);
              onSubmit();
            }}
          />
        </div>
      </Popup.Content>
    </Popup>
  );
}
