import classNames from "classnames";
import React, { PropsWithChildren, useState } from "react";
import { Accordion, Icon, Header } from "semantic-ui-react";

export function CollapsibleSection({
  title,
  indent = true,
  children,
}: PropsWithChildren<{ title: string; indent?: boolean }>) {
  const [active, setActive] = useState(false);
  return (
    <Accordion className='tw-border-none'>
      <Accordion.Title
        active={active}
        onClick={() => setActive((prev) => !prev)}
        className='tw-flex tw-select-none tw-items-center'
      >
        <Icon name='dropdown' />
        <Header as='h5' className='tw-m-0'>
          {title}
        </Header>
      </Accordion.Title>
      <Accordion.Content className={classNames("tw-pt-0", { "tw-ml-6": !!indent })} active={active}>
        {children}
      </Accordion.Content>
    </Accordion>
  );
}
