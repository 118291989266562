import React from "react";
import { Button, Modal } from "semantic-ui-react";
import { useNotifySubscriberContactMethodOptOut } from "../../../../hooks/useNotifySubscriberContactMethodOptOut";
import { buildIdentifierString } from "../utils/buildIdentifierString";
import { useToasts } from "react-toast-notifications";
import { useNotifySubscriberContactMethod } from "../../../../hooks/useNotifySubscriberContactMethod";

export function OptOutSubscriberModal({
  subscriberContactMethodId,
  isOpen,
  close,
  onSubmit = () => {},
}: {
  subscriberContactMethodId?: number;
  isOpen: boolean;
  close: () => void;
  onSubmit?: () => void;
}) {
  const { addToast } = useToasts();

  const { subscriberContactMethod } = useNotifySubscriberContactMethod({
    id: subscriberContactMethodId,
  });
  const { optOutSubscriberContactMethod, isOptOutSubscriberContactMethodLoading } =
    useNotifySubscriberContactMethodOptOut();

  return (
    <Modal open={isOpen} onClose={close} closeIcon size='tiny'>
      <Modal.Header>Opt-out {buildIdentifierString(subscriberContactMethod)}?</Modal.Header>
      <Modal.Content>
        They will be unsubscribed from all groups and removed from this list. To rejoin, they will need to receive and
        accept a new invite.
      </Modal.Content>
      <Modal.Actions>
        <Button content='Cancel' secondary onClick={close} />
        <Button
          content='Opt-out'
          negative
          disabled={isOptOutSubscriberContactMethodLoading}
          loading={isOptOutSubscriberContactMethodLoading}
          onClick={async () => {
            if (!subscriberContactMethodId) return;
            await optOutSubscriberContactMethod({ subscriberContactMethodIds: [subscriberContactMethodId] });

            close();
            addToast("Subscriber opted-out", { appearance: "success", autoDismiss: true });
            onSubmit();
          }}
        />
      </Modal.Actions>
    </Modal>
  );
}
