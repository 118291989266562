import { fetch as crossFetch } from "cross-fetch";
import type { History } from "history";
import type { Store } from "redux";
import AppSettings from "../config";
import JwtDecode from "jwt-decode";
import _ from "lodash";
import { getAccessToken } from "./auth";

export const ACTIVITY_STORAGE_KEY = "lastActivity";
export const ACTIVITY_THRESHOLD_MS = 1000 * 60 * 60; // 1 hour
let isTracking = false;

export let history: History, store: Store;

const getExcludedKickoutPaths = (site: string) => {
  return [`/${site}/kickout`, `/${site}/login`, `/${site}/public/login`, `/${site}/public/register`];
};
export const kickoutUser = (site: string) => {
  const location = window.location.pathname;
  if (getExcludedKickoutPaths(site).includes(location)) return;
  const isPublicOrigin = location.includes(`/${site}/public`);
  history.push(
    `/${site}/kickout?redirect=${encodeURIComponent(window.location.href)}&isPublicOrigin=${isPublicOrigin}`,
  );
};

/**
 * Passes the redux store and history object to the network module
 *
 * Note that we cannot just export the store and history objects from index,
 * it results in weird esoteric errors when running tests
 * @param storeRef Reference to the redux store
 * @param historyRef Reference to the history object
 */
export const initialize = (storeRef: Store, historyRef: History) => {
  store = storeRef;
  history = historyRef;
};

export const isInternal = (url?: string) => {
  if (!url) return false;
  return (
    url.includes(AppSettings.GOGOV_BASE_API_URL) ||
    url.includes(AppSettings.GOGOV_WEB_URL) ||
    url.includes(AppSettings.GOGOV_MOBILE_URL) ||
    url.includes(AppSettings.GOGOV_WEB_API_URL) ||
    url.includes(AppSettings.INTERNAL_ADMIN_API_URL)
  );
};

export const isAuthenticatingRequest = (url?: string) => {
  if (!url) return false;
  return url.includes(AppSettings.IDENTITY_API_URL);
};

type NetworkHandler = (site: string, response: Response | null, error: any, ...args: any[]) => void;
const checkNetworkError = (code: number, response: Response, handler: NetworkHandler | undefined, message: string) => {
  if (!handler || response.status !== code) return;
  const site = (store.getState().site as any).site;
  handler(site, response, null);
  throw new Error(message);
};
const checkThrownError = (code: number, error: any, handler: NetworkHandler | undefined, message: string) => {
  if (!handler || !(error?.response?.status === code || error?.status === code)) return;
  const site = (store.getState().site as any).site;
  handler(site, null, error);
  throw new Error(message);
};

/**
 * Tracks user activity by sending periodic updates to the backend
 * @param headers
 */
export async function trackUserActivity(headers: any): Promise<void> {
  if (isTracking) return;
  isTracking = true;
  try {
    const lastActivity = localStorage.getItem(ACTIVITY_STORAGE_KEY);
    const token = getAccessToken();
    if (!token) return;
    const userId = _.get(JwtDecode(token), "data.user.id");
    const now = Date.now();

    const [lastActivityUserId, lastActivityTime] = lastActivity?.split(":") || [];

    // If no previous activity or threshold exceeded, send update
    if (!lastActivity || now - parseInt(lastActivityTime) > ACTIVITY_THRESHOLD_MS || lastActivityUserId !== userId) {
      localStorage.setItem(ACTIVITY_STORAGE_KEY, `${userId}:${now}`);
      await crossFetch(`${AppSettings.GOGOV_BASE_API_URL}/core/persons/${userId}/activity/web`, {
        method: "PUT",
        headers: _.omit(headers, "Content-Type"),
      });
    }
  } catch (error) {
    console.warn(error);
    localStorage.removeItem(ACTIVITY_STORAGE_KEY);
  } finally {
    isTracking = false;
  }
}

/**
 * A wrapper around fetch that logs out the user if an internal API call returns 401
 * @param url Fetch url
 * @param init Fetch options
 * @param {Object} options - Handlers for different network errors
 * @param {Function} options.onUnauthorized - Function to call when unauthorized, default kickoutUser
 * @param {Function} options.onForbidden - Function to call when forbidden, default none
 * @param {Function} options.onNotFound - Function to call when not found, default none
 * @param {boolean} options.trackActivity - If this fetch should be tracked in the user's activity
 * @returns Fetch response
 */
export async function fetch(
  url: string,
  init?: RequestInit,
  {
    onUnauthorized,
    onForbidden,
    onNotFound,
    trackActivity,
  }: {
    onUnauthorized?: NetworkHandler;
    onForbidden?: NetworkHandler;
    onNotFound?: NetworkHandler;
    trackActivity?: boolean;
  } = {
    onUnauthorized: kickoutUser,
    trackActivity: true,
  },
): Promise<Response> {
  try {
    if (trackActivity) await trackUserActivity(init?.headers);
    const response = await crossFetch(url, init);

    if (isInternal(url)) {
      checkNetworkError(401, response, onUnauthorized, "Unauthorized");
      checkNetworkError(403, response, onForbidden, "Forbidden");
      checkNetworkError(404, response, onNotFound, "Not Found");
    }

    return response;
  } catch (error: any) {
    if (isInternal(url)) {
      checkThrownError(401, error, onUnauthorized, "Unauthorized");
      checkThrownError(403, error, onForbidden, "Forbidden");
      checkThrownError(404, error, onNotFound, "Not Found");
    }
    throw error;
  }
}

/**
 * A structured way of building API calls that logs out the user if an internal API call returns 401
 * @param query API query
 * @param parseResponse Some function to transform the response, usually a zod parser
 * @param isInternal If this is an internal API call, default true
 * @param {Object} handlers - Handlers for different network errors
 * @param {Function} handlers.onUnauthorized - Function to call when unauthorized, default kickoutUser
 * @param {Function} handlers.onForbidden - Function to call when forbidden, default none
 * @param {Function} handlers.onNotFound - Function to call when not found, default none
 * @returns Parsed response
 */
export async function buildQuery<Response, ParsedResponse>(
  query: Promise<Response>,
  parseResponse: (response: Response) => ParsedResponse,
  isInternal = true, // If this is a call to an internal API
  {
    onUnauthorized,
    onForbidden,
    onNotFound,
  }: {
    onUnauthorized?: NetworkHandler;
    onForbidden?: NetworkHandler;
    onNotFound?: NetworkHandler;
  } = {
    onUnauthorized: kickoutUser,
  },
): Promise<ParsedResponse> {
  try {
    if (isInternal)
      await trackUserActivity({
        "Authorization": `Bearer ${getAccessToken()}`,
        "X-GOGOVAPPS-SITE": location.pathname.split("/")[1],
      });
    const response = await query;
    return parseResponse(response);
  } catch (error: any) {
    if (isInternal) {
      checkThrownError(401, error, onUnauthorized, "Unauthorized");
      checkThrownError(403, error, onForbidden, "Forbidden");
      checkThrownError(404, error, onNotFound, "Not Found");
    }
    throw error;
  }
}

export const Network = { initialize, fetch, buildQuery };
